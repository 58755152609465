import React from "react"
import moment from "moment"
import { IInvoice, IInvoiceItem } from "../../types/interfaces/IInvoice"
import {
    Document,
    Page,
    Text,
    View,
} from '@react-pdf/renderer'
import { invoiceStyles, registerFonts, resolveCurrency } from "../use-invoice"
import { InvoiceFooter } from "../components/invoice-footer"
import { InvoiceAddress } from "../components/invoice-address"

interface IInvoiceProps {
    invoice: IInvoice
    title: string
    customer: any
    resourceStrings: any
}

export const InvoicePDF = (props: IInvoiceProps) => {
    registerFonts()
    const styles = invoiceStyles()
    const invoiceType = props.invoice.header.invoiceType === "CREDIT" ? "Credit Note" : "Invoice"

    return (
        <Document
            title={props.title}
            author="Horizon Platforms"
            subject={"Copy invoice"}
        >
            <Page size="A4">
                <View style={styles.body}>
                    <View style={[styles.container, styles.spacerExtraLarge]}>
                        <View style={styles.halfContainer}>
                            <Text style={[styles.text, styles.textLarge, styles.textBold, styles.textUpperCase]}>{props.title}</Text>
                        </View>
                        <View style={[styles.halfContainer, styles.alignRightContainer, styles.alignVerticalCenterContainer]}>
                            <Text style={styles.textMedium}>
                                {`${invoiceType} No: ${props.invoice.header.invoiceNo}`}
                            </Text>
                        </View>
                        <View style={styles.spacer}></View>
                    </View>
                    <View style={styles.container}>
                        <View style={styles.tableBorder}>
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCellBorder, styles.tableCellHalf]}>
                                    <View style={styles.spacerSmall}></View>
                                    <Text style={styles.tableCellTextLarge}>{props.customer.customerName}</Text>
                                    <InvoiceAddress
                                        name={props.customer.customerName}
                                        line1={props.customer.addressLine1}
                                        line2={props.customer.addressLine2}
                                        line3={props.customer.addressLine3}
                                        town={props.customer.addressTown}
                                        county={props.customer.addressCounty}
                                        postcode={props.customer.addressPostcode}
                                    />
                                    <View style={styles.spacer}></View>
                                    <Text style={[styles.tableCellText, styles.textRight]}>{props.customer.accountNo}</Text>
                                    <View style={styles.spacerSmall}></View>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellHalf]}>
                                    <View style={styles.spacerSmall}></View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellTitle}>
                                            <Text style={[styles.tableCellText, styles.textBold]}>Site:</Text>
                                        </View>
                                        <View style={styles.tableCellContent}>
                                            <InvoiceAddress
                                                name={props.invoice.header.siteName}
                                                line1={props.invoice.header.siteLine1}
                                                line2={props.invoice.header.siteLine2}
                                                line3={props.invoice.header.siteLine3}
                                                town={props.invoice.header.siteTown}
                                                county={props.invoice.header.siteCounty}
                                                postcode={props.invoice.header.sitePostcode}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.spacerSmall}></View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellTitle}>
                                            <Text style={[styles.tableCellText, styles.textBold]}>Invoice Date:</Text>
                                        </View>
                                        <View style={styles.tableCellContent}>
                                            <Text style={styles.tableCellText}>{moment(props.invoice.header.invoiceDate).format("DD/MM/YYYY")}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.spacerSmall}></View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellTitle}>
                                            <Text style={[styles.tableCellText, styles.textBold]}>PO No:</Text>
                                        </View>
                                        <View style={styles.tableCellContent}>
                                            <Text style={styles.tableCellText}>{props.invoice.header.customerRef}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.spacerSmall}></View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellTitle}>
                                            <Text style={[styles.tableCellText, styles.textBold]}>Contract No:</Text>
                                        </View>
                                        <View style={styles.tableCellContent}>
                                            <Text style={styles.tableCellText}>{props.invoice.header.contractId}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.spacerSmall}></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.container}>
                        <View style={styles.table}>
                            <View style={styles.tableRowBorder}>
                                <View style={[styles.tableCellBorder, styles.tableCellLineDate]}>
                                    <Text style={[styles.tableCellText, styles.textBold]}>From</Text>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellLineDate]}>
                                    <Text style={[styles.tableCellText, styles.textBold]}>To</Text>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellLineDescription]}>
                                    <Text style={[styles.tableCellText, styles.textBold]}>Description</Text>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellLineValue]}>
                                    <Text style={[styles.tableCellText, styles.textRight, styles.textBold]}>Value</Text>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellLineVAT]}>
                                    <Text style={[styles.tableCellText, styles.textRight, styles.textBold]}>VAT</Text>
                                </View>
                            </View>
                            {props.invoice.items.map((item: IInvoiceItem) => (
                                <View style={styles.tableRow}>
                                    <View style={[styles.tableCell, styles.tableCellLineDate]}>
                                        {item.dateFrom && (
                                            <Text style={styles.tableCellText}>{moment(item.dateFrom).format("DD/MM/YYYY")}</Text>
                                        )}
                                    </View>
                                    <View style={[styles.tableCell, styles.tableCellLineDate]}>
                                        {item.dateTo && (
                                            <Text style={styles.tableCellText}>{moment(item.dateTo).format("DD/MM/YYYY")}</Text>
                                        )}
                                    </View>
                                    <View style={[styles.tableCell, styles.tableCellLineDescription]}>
                                        <Text style={styles.tableCellText}>{item.invoiceDetails}</Text>
                                    </View>
                                    <View style={[styles.tableCell, styles.tableCellLineValue]}>
                                        <Text style={[styles.tableCellText, styles.textRight]}>{resolveCurrency(item.lineAmount)}</Text>
                                    </View>
                                    <View style={[styles.tableCell, styles.tableCellLineVAT]}>
                                        <Text style={[styles.tableCellText, styles.textRight]}>{`${resolveCurrency(item.vatAmount)} @ ${item.vatRate}%`}</Text>
                                    </View>
                                </View>
                            ))}
                        </View>
                    </View>
                    {props.invoice.header.invoiceNotes && (
                        <>
                            <View style={styles.spacer}></View>
                            <View style={styles.container}>
                                <Text style={[styles.fullWidth, styles.text, styles.textCenter]}>{props.invoice.header.invoiceNotes}</Text>
                            </View>
                        </>
                    )}
                    <View style={styles.spacer}></View>
                    <View style={styles.container}>
                        <Text style={[styles.fullWidth, styles.text, styles.textMedium, styles.textCenter, styles.textBold, styles.textItalic, styles.textUpperCase]}>
                            {`** Copy ${invoiceType} **`}
                        </Text>
                    </View>
                    <InvoiceFooter
                        resourceStrings={props.resourceStrings}
                        showBankDetails={props.invoice.header.invoiceType !== "CREDIT"}
                        subTotal={props.invoice.header.lineTotal}
                        total={props.invoice.header.invoiceTotal}
                        vatTotal={props.invoice.header.vatTotal}
                    />
                </View>
            </Page>
        </Document>
    )
}