import React from "react"
import _ from "lodash"
import { Text } from '@react-pdf/renderer'
import { invoiceStyles } from "../use-invoice"

export interface IInvoiceAddress {
    name: string
    line1: string
    line2: string
    line3: string
    town: string
    county: string
    postcode: string
}

const styles = invoiceStyles()

export const InvoiceAddress = (props: IInvoiceAddress) => {
    return (
        <>
            {_.uniq([
                props.name,
                props.line1,
                props.line2,
                props.line3,
                props.town,
                props.county,
                props.postcode,
            ])
                .filter(Boolean)
                .map((item: string) => {
                    return (
                        <Text style={styles.tableCellText}>{item}</Text>
                    )
                })}
        </>
    )
}