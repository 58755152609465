import React, { ForwardedRef, forwardRef } from "react"
import { AlertBanner } from "../../../../../../alert-banner"
import { AddContractLogEntry } from "../../../../../../add-contract-log-entry"
import { HireInsuranceStatus } from "../../../../../../hire-insurance-status"
import { ContractLogModal } from "../../../../../../contract-log-modal"
import { ContactsListModal } from "../../../../../../contacts-list-modal"

export interface SummaryProps {
  summary: string
  contractType?: string
  customerContract: Record<string, any> | null
  hireDetails: Record<string, any>
  deliveryDetails: Record<string, any>
  orderDetails: Record<string, any>
  autoNotes: string
  hasInsurance: boolean
  accidentalDamageWaiverPercentage?: number
  theftAndLossWaiverPercentage?: number
  customer: Record<string, any> | null
  excludedPostcode: boolean
  deliveryTimeOptions: Record<string, any>[]
}

export const Summary = forwardRef(
  (
    {
      summary,
      contractType,
      customerContract,
      hireDetails,
      deliveryDetails,
      orderDetails,
      autoNotes,
      hasInsurance,
      accidentalDamageWaiverPercentage,
      theftAndLossWaiverPercentage,
      customer,
      excludedPostcode,
      deliveryTimeOptions,
    }: SummaryProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const {
      deliveryCharge,
      collectionCharge,
      siteAddress,
      onHireDate,
      offHireDate,
      deliveryDate,
      deliveryTime,
    } = deliveryDetails || {}

    const { addressDirections } = siteAddress?.value || {}

    const deliveryTimeStr = deliveryTimeOptions.find(
      ({ value }) => value === deliveryTime
    )?.label || deliveryTime

    const getItemsDependingOnFuelCategory = (isFuel: boolean) => {
      return hireDetails.filter((item: Record<string, any>) => {
        const { equipment, quantity } = item || {}
        const { pricingEquipmentClass, pcode } = equipment?.value || {}
        const categoryCheck = isFuel
          ? pricingEquipmentClass === "FUEL"
          : pricingEquipmentClass !== "FUEL"
        return (
          pricingEquipmentClass &&
          categoryCheck &&
          pcode &&
          quantity &&
          +quantity > 0
        )
      })
    }

    const items = getItemsDependingOnFuelCategory(false)

    const independentFuelItems = getItemsDependingOnFuelCategory(true)

    const formattedIndependentFuelItems = independentFuelItems.map(
      (item: Record<string, any>) => {
        const { value } = item.equipment || {}
        return {
          equipmentCategory: value?.pricingEquipmentClass,
          stockNumber: value?.pcode,
          parentItemSeq: null,
          sequenceNo: item?.sequenceNo,
          equipmentDesc: value?.equipmentDescription,
          chargePeriod: item?.hirePeriod.value,
          quantity: +item?.quantity,
          taxCode: "T5",
          unitCharge: +item?.unitPrice,
          chargeAmount: +item?.hireCharge,
        }
      }
    )

    const hireDetailsExist = !!items && items.length > 0

    // Temporary fix because the summary doesn't calculate anything other than qty x unit price
    // If there is a 5WEEK for any item, change wording to "total weekly charge"
    const isWeeklyCharge = !!items.find((item: Record<string, any>) => {
      return item.hirePeriod.value === "5WEEK"
    })

    const isMonthlyCharge = !!items.find((item: Record<string, any>) => {
      return item.hirePeriod.value === "MONTH"
    })

    const attachedFuelItems = items
      .filter((item: Record<string, any>) => !!item.fuelItem)
      .map((item: Record<string, any>) => item.fuelItem)
    const fuelItems = [...attachedFuelItems, ...formattedIndependentFuelItems]
    const fuelItemsExist = !!fuelItems && fuelItems.length > 0
    const totalFuelCharge = fuelItems.reduce((total: any, item: any) => {
      const { chargeAmount } = item || {}
      if (chargeAmount) {
        total += parseFloat(chargeAmount)
      }
      return total
    }, 0)

    const totalHireCost = hireDetails.reduce((total: any, item: any) => {
      const { equipment, hireCharge } = item || {}
      const isFuelCategory = equipment?.value?.pricingEquipmentClass === "FUEL"
      if (!isFuelCategory && hireCharge) {
        total += parseFloat(hireCharge)
      }
      return total
    }, 0)

    const calculateTotalCharge = () => {
      let charge = totalHireCost
      if (!(isWeeklyCharge || isMonthlyCharge)) {
        // Add transport if a fixed period
        charge += parseFloat(deliveryCharge ? deliveryCharge : 0) + parseFloat(collectionCharge ? collectionCharge : 0)
      }
      if (hireDetailsExist && !hasInsurance) {
        if (!!theftAndLossWaiverPercentage) {
          charge += totalHireCost * (theftAndLossWaiverPercentage / 100)
        }
        if (!!accidentalDamageWaiverPercentage) {
          charge += totalHireCost * (accidentalDamageWaiverPercentage / 100)
        }
      }
      return charge
    }

    const totalCharge = calculateTotalCharge()

    return (
      <div className="sticky top-0">
        <div className="flex">
          <h2 className="mt-4 mb-4 text-xl font-bold uppercase">{summary}</h2>
          <AddContractLogEntry
            contractId={customerContract?.contractId}
            depotId={parseInt(customerContract?.contractDepotId)}
            allowQuoteReady={customerContract?.contractType === "Quotation"}
          />
          <ContractLogModal
            contractId={customerContract?.contractId}
          />
          <ContactsListModal
            customerId={customerContract?.customerId}
            customerName={customerContract?.customerName}
            linkClassNames="ml-4"
          />
        </div>
        <div className="bg-white border sm:rounded-lg">
          <div className="p-4 text-sm uppercase" id="contractSummary" ref={ref}>
            <p className="text-xl font-bold uppercase">
              Contract #{customerContract?.contractNo}
            </p>
            <p className="pt-5">- {contractType}</p>

            {hireDetailsExist && (
              <div className="pt-5">
                {items.map(
                  ({
                    id,
                    description,
                    quantity,
                    equipment,
                    unitPrice,
                    hireCharge,
                  }: Record<string, any>) => {
                    const { value } = equipment || {}
                    return (
                      <p className="uppercase" key={id}>
                        {description
                          ? value
                            ? `- ${quantity} x ${value ? value.pricingEquipmentClass : ""
                            } ${value ? value.pcode : ""} @ £${unitPrice ? parseFloat(unitPrice).toFixed(2) : 0
                            } = £${parseFloat(hireCharge || 0).toFixed(2)}`
                            : ""
                          : ""}
                      </p>
                    )
                  }
                )}
              </div>
            )}
            {!(isWeeklyCharge || isMonthlyCharge) && !!deliveryCharge && (
              <p className="pt-5">- Delivery Charge: £{deliveryCharge}</p>
            )}
            {!(isWeeklyCharge || isMonthlyCharge) && !!collectionCharge && (
              <p>- Collection Charge: £{collectionCharge}</p>
            )}

            {hireDetailsExist && !hasInsurance && (
              <>
                {!!theftAndLossWaiverPercentage && (
                  <p className="pt-5">
                    - Theft & Loss Waiver @{theftAndLossWaiverPercentage}% of
                    the total hire cost:{" "}
                    {theftAndLossWaiverPercentage
                      ? `£${(
                        totalHireCost *
                        (theftAndLossWaiverPercentage / 100)
                      ).toFixed(2)}`
                      : "£0"}
                  </p>
                )}
                {!!accidentalDamageWaiverPercentage && (
                  <p>
                    - Damage Waiver @{accidentalDamageWaiverPercentage}% of the
                    total hire cost:{" "}
                    {accidentalDamageWaiverPercentage
                      ? `£${(
                        totalHireCost *
                        (accidentalDamageWaiverPercentage / 100)
                      ).toFixed(2)}`
                      : "£0"}
                  </p>
                )}
              </>
            )}

            <p className="pt-5">
              {parseFloat(totalCharge)
                ? `- Total ${isWeeklyCharge ? "weekly" : isMonthlyCharge ? "monthly" : ""} charge: £${(
                  parseFloat(totalCharge)
                ).toFixed(2)
                }`
                : ""}
            </p>

            {(isWeeklyCharge || isMonthlyCharge) && !!deliveryCharge && <p className="pt-5">- Delivery Charge: £{deliveryCharge}</p>}
            {(isWeeklyCharge || isMonthlyCharge) && !!collectionCharge && (
              <p>- Collection Charge: £{collectionCharge}</p>
            )}
            {fuelItemsExist && (
              <div className="pt-5">
                {fuelItems.map((fuelItem: Record<string, any>, i: number) => (
                  <p key={i}>
                    - {fuelItem.quantity} x {fuelItem.equipmentCategory}{" "}
                    {fuelItem.stockNumber} @ £{fuelItem.unitCharge} = £
                    {fuelItem.chargeAmount?.toFixed(2)}
                  </p>
                ))}
                <p className="pt-5">
                  - Total Fuel Charge: £{totalFuelCharge?.toFixed(2)}
                </p>
              </div>
            )}
            <p className="pt-5">
              {siteAddress?.label ? `- Site address: ${siteAddress.label}` : ""}
            </p>
            {excludedPostcode && (
              <p className="pt-2 font-bold truncate text-red-600">
                - EXCLUSION POSTCODE
              </p>
            )}
            <p className="pt-5">
              {addressDirections ? `- Directions: ${addressDirections}` : ""}
            </p>
            <p className="pt-5">
              {onHireDate ? `- On hire: ${onHireDate.toDateString()}` : ""}
            </p>
            <p className="pt-5">
              {offHireDate
                ? `- Off hire: ${offHireDate.toDateString()}`
                : onHireDate
                  ? "- Off hire: open"
                  : ""}
            </p>
            <p className="pt-5">
              {deliveryDate
                ? `- Delivery: ${deliveryDate.toDateString()} ${deliveryTimeStr ? `(${deliveryTimeStr})` : ""
                }`
                : ""}
            </p>
            <p className="pt-5">{autoNotes}</p>
            <p className="pt-5">
              {orderDetails.notes ? `- Notes: ${orderDetails.notes}` : ""}
            </p>
          </div>
        </div>
        <HireInsuranceStatus
          hasInsurance={customer?.hasInsurance}
          insuranceRenewalDate={customer?.insuranceRenewalDate}
          classNames="my-8"
        />
        {customer?.alertText &&
          <div className="mt-6">
            <AlertBanner text={customer?.alertText} />
          </div>
        }
        {customer?.notes &&
          <div className="mt-6">
            <AlertBanner text={customer?.notes} isInfo={true} />
          </div>
        }
      </div>
    )
  }
)
