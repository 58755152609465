import React from "react"
import moment from "moment"
import { IProformaInvoice, IProformaInvoiceItem } from "../../types/interfaces/IProformaInvoice"
import {
    Document,
    Page,
    Text,
    View,
} from '@react-pdf/renderer'
import { invoiceStyles, registerFonts, resolveCurrency } from "../use-invoice"
import { InvoiceFooter } from "../components/invoice-footer"
import { InvoiceAddress } from "../components/invoice-address"

interface IInvoiceProps {
    invoice: IProformaInvoice
    resourceStrings: any
}

export const ProformaInvoicePDF = (props: IInvoiceProps) => {
    registerFonts()
    const styles = invoiceStyles()
    const title = "Proforma Invoice"

    const subTotal = props.invoice.items.reduce((acc: number, curr: IProformaInvoiceItem) => {
        return acc + curr.totalCharge || 0
    }, 0)

    const vatTotal = props.invoice.items.reduce((acc: number, curr: IProformaInvoiceItem) => {
        return acc + curr.vatCharge || 0
    }, 0)

    return (
        <Document
            title={title}
            author="Horizon Platforms"
            subject={title}
        >
            <Page size="A4">
                <View style={styles.body}>
                    <View style={[styles.container, styles.spacerExtraLarge]}>
                        <View style={styles.halfContainer}>
                            <Text style={[styles.text, styles.textLarge, styles.textBold, styles.textUpperCase]}>{title}</Text>
                        </View>
                        <View style={[styles.halfContainer, styles.alignRightContainer, styles.alignVerticalCenterContainer]}>
                        </View>
                        <View style={styles.spacer}></View>
                    </View>
                    <View style={styles.container}>
                        <View style={styles.tableBorder}>
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCellBorder, styles.tableCellHalf]}>
                                    <View style={styles.spacerSmall}></View>
                                    <Text style={styles.tableCellTextLarge}>{props.invoice.header.customerName}</Text>
                                    <InvoiceAddress
                                        name={props.invoice.header.customerName}
                                        line1={props.invoice.customerAddress.addressLine1}
                                        line2={props.invoice.customerAddress.addressLine2}
                                        line3={props.invoice.customerAddress.addressLine3}
                                        town={props.invoice.customerAddress.addressTown}
                                        county={props.invoice.customerAddress.addressCounty}
                                        postcode={props.invoice.customerAddress.addressPostcode}
                                    />
                                    <View style={styles.spacer}></View>
                                    <Text style={[styles.tableCellText, styles.textRight]}>{props.invoice.header.customerAccountNo}</Text>
                                    <View style={styles.spacerSmall}></View>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellHalf]}>
                                    <View style={styles.spacerSmall}></View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellTitle}>
                                            <Text style={[styles.tableCellText, styles.textBold]}>Site:</Text>
                                        </View>
                                        <View style={styles.tableCellContent}>
                                            <InvoiceAddress
                                                name={props.invoice.siteAddress.addressName}
                                                line1={props.invoice.siteAddress.addressLine1}
                                                line2={props.invoice.siteAddress.addressLine2}
                                                line3={props.invoice.siteAddress.addressLine3}
                                                town={props.invoice.siteAddress.addressTown}
                                                county={props.invoice.siteAddress.addressCounty}
                                                postcode={props.invoice.siteAddress.addressPostcode}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.spacerSmall}></View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellTitle}>
                                            <Text style={[styles.tableCellText, styles.textBold]}>Invoice Date:</Text>
                                        </View>
                                        <View style={styles.tableCellContent}>
                                            <Text style={styles.tableCellText}>{moment().format("DD/MM/YYYY")}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.spacerSmall}></View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellTitle}>
                                            <Text style={[styles.tableCellText, styles.textBold]}>Date Of Hire:</Text>
                                        </View>
                                        <View style={styles.tableCellContent}>
                                            <Text style={styles.tableCellText}>{moment(props.invoice.header.startDate).format("DD/MM/YYYY")}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellTitle}>
                                            <Text style={[styles.tableCellText, styles.textBold]}>Invoice To Date:</Text>
                                        </View>
                                        <View style={styles.tableCellContent}>
                                            <Text style={styles.tableCellText}>{props.invoice.header.endDate ? moment(props.invoice.header.endDate).format("DD/MM/YYYY") : ""}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.spacerSmall}></View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellTitle}>
                                            <Text style={[styles.tableCellText, styles.textBold]}>Contract No:</Text>
                                        </View>
                                        <View style={styles.tableCellContent}>
                                            <Text style={styles.tableCellText}>{props.invoice.header.contractNo}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.spacerSmall}></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.container}>
                        <View style={styles.table}>
                            <View style={styles.tableRowBorder}>
                                <View style={[styles.tableCellBorder, styles.tableCellLineDescriptionProforma]}>
                                    <Text style={[styles.tableCellText, styles.textBold]}>Description</Text>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellLineQty]}>
                                    <Text style={[styles.tableCellText, styles.textBold, styles.textCenter]}>Qty</Text>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellLineValue]}>
                                    <Text style={[styles.tableCellText, styles.textRight, styles.textBold]}>Value</Text>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellLineChargePer]}>
                                    <Text style={[styles.tableCellText, styles.textBold]}>Per</Text>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellLineValue]}>
                                    <Text style={[styles.tableCellText, styles.textRight, styles.textBold]}>Total</Text>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellLineVAT]}>
                                    <Text style={[styles.tableCellText, styles.textRight, styles.textBold]}>VAT</Text>
                                </View>
                            </View>
                            {props.invoice.items.map((item: IProformaInvoiceItem) => (
                                <>
                                    <View style={[styles.tableRow, styles.fullWidth]}>
                                        <View style={[styles.tableCell, styles.tableCellLineDescriptionProforma]}>
                                            <Text style={styles.tableCellText}>{item.itemDescription}</Text>
                                        </View>
                                        <View style={[styles.tableCell, styles.tableCellLineQty]}>
                                            <Text style={[styles.tableCellText, styles.textCenter]}>{item.quantity}</Text>
                                        </View>
                                        <View style={[styles.tableCell, styles.tableCellLineValue]}>
                                            <Text style={[styles.tableCellText, styles.textRight]}>{resolveCurrency(item.charge)}</Text>
                                        </View>
                                        <View style={[styles.tableCell, styles.tableCellLineChargePer]}>
                                            <Text style={styles.tableCellText}>{item.chargePer}</Text>
                                        </View>
                                        <View style={[styles.tableCell, styles.tableCellLineValue]}>
                                            <Text style={[styles.tableCellText, styles.textRight]}>{resolveCurrency(item.totalCharge)}</Text>
                                        </View>
                                        <View style={[styles.tableCell, styles.tableCellLineVAT]}>
                                            <Text style={[styles.tableCellText, styles.textRight]}>{`${resolveCurrency(item.vatCharge)} @ ${item.vatRate}%`}</Text>
                                        </View>
                                    </View>
                                    {item.itemNotes &&
                                        <View style={[styles.tableRow, styles.fullWidth]}>
                                            <Text style={[styles.tableCellText, styles.fullWidth, styles.textCenter]}>{item.itemNotes}</Text>
                                        </View>
                                    }
                                </>
                            ))}
                        </View>
                    </View>
                    {props.invoice.header.text1 && (
                        <>
                            <View style={styles.spacer}></View>
                            <View style={[styles.container, styles.paddingX]}>
                                <Text style={[styles.fullWidth, styles.text, styles.textCenter]}>{props.invoice.header.text1}</Text>
                            </View>
                        </>
                    )}
                    {props.invoice.header.text2 && (
                        <>
                            <View style={styles.spacer}></View>
                            <View style={[styles.container, styles.paddingX]}>
                                <Text style={[styles.fullWidth, styles.text, styles.textCenter]}>{props.invoice.header.text2}</Text>
                            </View>
                        </>
                    )}
                    <InvoiceFooter
                        resourceStrings={props.resourceStrings}
                        showBankDetails={true}
                        subTotal={subTotal}
                        total={subTotal + vatTotal}
                        vatTotal={vatTotal}
                    />
                </View>
            </Page>
        </Document>
    )
}