import React, { useEffect, useState, useRef } from "react"
import Select from "react-select"
import { navigate } from "gatsby-link"
import { useMsal } from "@azure/msal-react"
import { v4 as uuidv4 } from "uuid"
import { useCustomerContext } from "../../../store"
import { useResourceStringsNewContracts } from "../use-resource-strings"
import moment from 'moment';

import {
  getContactsByCustomerId,
  getCustomerWithSavedContractsByContractId,
  getSitesForCustomerSavedContractsByCustomerId,
  getContactsForSiteBySiteId,
  getEquipmentList,
  saveContract,
  listDocuments,
  getPreviousEquipmentChargesByCustomer,
  getPreviousExclusionPostcodeTransportChargesByCustomer,
  cancelContract,
  getCustomerPropertiesByCustomerId,
  getContractCancellationReasons,
  duplicateContract,
  getProformaInvoice,
  logProformaInvoiceCreated,
} from "../../../../../../../middleware/middleware-layer"

import {
  IBadData,
  IDeliveryContacts,
  IDeliveryDetails,
} from "../../../../../../../types/interfaces"

import {
  AlertMessage,
  OrderDetailsForm,
  DeliveryDetailsForm,
  HireDetailsForm,
} from "../../../../../../../components"
import { transformSiteObjToOption } from "../../../../../../../utils/transformSiteObjToOption"
import { transformContactObjToOption } from "../../../../../../../utils/transformContactObjToOption"
import { transformEquipmentObjToOption } from "../../../../../../../utils/transformEquipmentObjToOption"
import { Button } from "../../../../../../button"
import { ConfirmationModal } from "./confirmation-modal"
import { Summary } from "./summary"
import {
  addFuelLineItem,
  getSplitDeliveryNotesAtIndex,
  hirePeriodPriceKeyMap,
} from "../../../../../../../utils/contract-helper"
import { resourceStringExtractor } from "../../../../../../../utils/resource-string-extractor"
import { Loader } from "../../../../../../loading-feedback/loader"
import { ICheckBox } from "../../../../../../../types/interfaces/ICheckBox"
import { pdf } from '@react-pdf/renderer';
import { uploadToBlobClient } from "../../../../../../../utils/upload-helper"
import { ContractConfirmationPDF } from "../../../../../../../pdf-templates/contract-confirmation"
import { userName } from "../../../../../../msal/userName";
import { IContractConfirmation, IContractConfirmationItem } from "../../../../../../../types/interfaces/IContractConfirmation"
import { getLocalMidnightISOString } from "../../../../../../../utils/date-helper"
import { useAllKontentPostcodeExclusionsData } from "../../../../../../../graphql-static/use-postcode-exclusions"
import { isPostcodeExcluded } from "../../../../../../../utils/postcode-exclusions-helper"
import { SiteSurveyStatus } from "../../../../../../site-survey-status"
import { SiteSurveyCompletionModal } from "../../../../../../site-survey-completion-modal"
import _ from "lodash"
import { SiteSurveyAssignSurveyorModal } from "../../../../../../site-survey-assign-surveyor-modal"
import { FleetDayBookModal } from "../../../../../../fleet-day-book-modal"
import { ContractAuthorisationCodeModal } from "../../../../../../contract-authorisation-code-modal"
import { LoadingFeedback } from "../../../../../../loading-feedback"
import { Modal } from "../../../../../../modal"
import { useResourceStringsInvoices } from "../../../../../../../pdf-templates/use-resource-strings"
import { IProformaInvoice } from "../../../../../../../types/interfaces/IProformaInvoice"
import { ProformaInvoicePDF } from "../../../../../../../pdf-templates/proforma-invoice"
import { downloadBlob } from "../../../../../../../utils/download-helper"

export default function NewContractDetails({
  customerId,
  contractId,
}: Record<string, any>) {

  const uploadedBy = userName()
  const summaryRef = useRef<HTMLDivElement>(null)

  const { customer, isCustomerLoading } = useCustomerContext()
  const {
    hireDetails,
    deliveryDetails,
    orderDetails,
    summary,
    alertOnHold,
    firmBooking,
    provisionalBooking,
    quote,
    futureEnquiry,
    immediateEnquiry,
    complexEnquiry,
  } = useResourceStringsNewContracts()
  const excludedPostcodes = useAllKontentPostcodeExclusionsData()
  const invoiceResourceStrings = useResourceStringsInvoices()
  const statusIsOnHold = customer?.isOnHold === "true"
  const statusHasExceededCreditLimit = Number(customer?.balance) > Number(customer?.creditLimit)
  const isProspect = customer?.companyStatus === "P"
  const { instance, accounts } = useMsal()
  const [isContractLoading, setIsContractLoading] = useState<boolean>(false)
  const [isEquipmentListLoading, setIsEquipmentListLoading] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isCancelling, setIsCancelling] = useState<boolean>(false)
  const [isDuplicating, setIsDuplicating] = useState<boolean>(false)
  const [isSiteSurveyAssignSurveyorModalOpen, setSiteSurveyAssignSurveyorModalOpen] = useState<boolean>(false)
  const [isSiteSurveyModalOpen, setSiteSurveyModalOpen] = useState<boolean>(false)
  const [deliverySites, setDeliverySites] = useState<IDeliveryDetails[]>([])
  const [deliveryContacts, setDeliveryContacts] = useState<IDeliveryContacts[]>(
    []
  )
  const [equipmentList, setEquipmentList] = useState<Record<string, any>[]>([])
  const [orderContacts, setOrderContacts] = useState([])
  const [customerContract, setCustomerContract] = useState<Record<
    string,
    any
  > | null>(null)
  const [showBadDataMessage, setShowBadDataMessage] = useState<IBadData>({
    showMessage: false,
    message: "",
  })
  const [formStatus, setFormStatus] = useState<Record<string, any>>({
    status: "",
    messages: [],
  })

  const [loadingStatus, setLoadingStatus] = useState<Record<string, any>>({
    contract: false,
    equipment: false,
    sites: false,
    contacts: false,
  })

  const [contractDocuments, setContractDocuments] = useState<Record<string, any>[]>([])
  const [previousChargesByEquipment, setPreviousChargesByEquipment] = useState<Record<string, any>>({})
  const [excludedPostcode, setExcludedPostcode] = useState<Record<string, any>>({})
  const [previousExcludedPostcodeTransportCharges, setPreviousExcludedPostcodeTransportCharges] = useState<Record<string, any>>({})
  const [isEmptyTransportCharges, setIsEmptyTransportCharges] = useState<boolean>(false)
  const [additionalTransport, setAdditionalTransport] = useState<Record<string, any>>([])
  const [disableAddHireDetailItem, setDisableAddHireDetailItem] = useState<boolean>(true)
  const [purchaseOrderNoValidation, setPurchaseOrderNoValidation] = useState<Record<string, any>>({})
  const [cancellationReasons, setCancellationReasons] = useState<string[]>([])
  const [cancellationReason, setCancellationReason] = useState<string>("")
  const [isFleetDayBookModalOpen, setIsFleetDayBookModalOpen] = useState<boolean>(false)
  const [isZeroCustomerTransportRateWarningVisible, setIsZeroCustomerTransportRateWarningVisible] = useState<boolean>(false)
  const [canFlipToFirmBookingWithAuthCode, setCanFlipToFirmBookingWithAuthCode] = useState<boolean>(false)
  const [isAuthorisationCodeModalOpen, setIsAuthorisationCodeModalOpen] = useState<boolean>(false)
  const [isProformaInvoicePDFModalOpen, setIsProformaInvoicePDFModalOpen] = useState<boolean>(false)
  const [hasExistingProforma, setHasExistingProforma] = useState<boolean>(false)
  const [isCreatingProforma, setIsCreatingProforma] = useState<boolean>(false)

  const checkTransportCharges = (deliveryCharge: any, collectionCharge: any) => {
    const isEmpty = !(deliveryCharge && deliveryCharge > 0 && collectionCharge && collectionCharge > 0)
    setIsEmptyTransportCharges(isEmpty)
    if (isEmpty && window.scrollY > 200) {
      window.scrollTo({ top: 200, behavior: "smooth" });
    }
  }

  const initialRenderHirePeriod = useRef(true)

  const hirePeriodOptions = [
    { label: "1-day", value: "1 DAY" },
    { label: "2-day", value: "2 DAY" },
    { label: "3-day", value: "3 DAY" },
    { label: "5 Week (5 days)", value: "5WEEK" },
    { label: "Month", value: "MONTH" },
  ]

  const orderDetailEnquiryTypes: Record<string, any>[] = [
    { value: immediateEnquiry, label: immediateEnquiry },
    { value: futureEnquiry, label: futureEnquiry },
    { value: complexEnquiry, label: complexEnquiry },
  ]

  const initialHireDetailItem = {
    id: uuidv4(),
    sequenceNo: null,
    equipment: { label: "", value: null },
    description: "",
    xh: false,
    quantity: "",
    unitPrice: "",
    hirePeriod: { label: "5 Week (5 days)", value: "5WEEK" },
    hireCharge: "",
    fuelItem: null,
  }

  const [contractType, setContractType] = useState("Quotation")

  useEffect(() => {
    const customerContractType = customerContract?.contractType
    if (statusIsOnHold || isProspect || statusHasExceededCreditLimit) {
      const contractTypeToSet = customerContractType || "Quotation"
      setContractType(contractTypeToSet)

      if (contractTypeToSet === "Quotation") {
        setCanFlipToFirmBookingWithAuthCode(true)
      }
    } else {
      setContractType(customerContractType || "Firm Booking")
    }
  }, [customerContract?.contractType])

  const contractTypeMap: Record<string, any> = {
    "Firm Booking": firmBooking,
    "Provisional Booking": provisionalBooking,
    Quotation: quote,
  }

  const contractTypeOptions = [{ value: "Quotation", label: quote }]

  if ((statusIsOnHold || isProspect || statusHasExceededCreditLimit) && contractType !== "Quotation") {
    contractTypeOptions.push({
      value: contractType,
      label: contractTypeMap[contractType],
    })
  } else if (!statusIsOnHold && !isProspect && !statusHasExceededCreditLimit) {
    contractTypeOptions.push(
      { value: "Provisional Booking", label: provisionalBooking },
      { value: "Firm Booking", label: firmBooking }
    )
  }

  const initialHireDetailsFormStateArr = [initialHireDetailItem]

  const [hireDetailsFormValuesArr, setHIreDetailsFormValuesArr] = useState<
    Record<string, any>[]
  >(initialHireDetailsFormStateArr)

  const [lowestHirePeriod, setLowestHirePeriod] = useState<Number>()

  const handleHireDetailArrChange = (id: string, key: string, value: any) => {
    setHIreDetailsFormValuesArr(prevArr => {
      return prevArr.map(item => {
        if (item.id === id) {
          switch (key) {
            case "equipment":
              item.equipment = value
              item.description = value?.value?.equipmentDescription || ""
              item.xh =
                value?.value?.pricingEquipmentClass === "XH" ||
                value?.value?.pricingEquipmentClass === "XHACC"
              item.quantity = value ? "1" : ""
              const isFuelCategory =
                item.equipment?.value?.pricingEquipmentClass === "FUEL"
              item.unitPrice =
                value?.value[
                  isFuelCategory ? "pricingCharge" : "pricingWeek"
                ]?.toString() || ""
              item.hirePeriod = isFuelCategory
                ? { label: "SOR", value: "SOR" }
                : initialHireDetailItem?.hirePeriod
              item.hireCharge = item.unitPrice?.toString()

              if (item.equipment) {
                item.fuelItem = addFuelLineItem(item.sequenceNo, item.equipment.value)
              } else {
                item.fuelItem = null
              }
              break
            case "xh":
              item.xh = value
              if (item.fuelItem) {
                let matchingFuelItemParent
                if (value) {
                  // Find the matching cross hire fuel item
                  matchingFuelItemParent = equipmentList.find((listItem) =>
                    listItem.value.pcode === `XH${item.equipment.value.pricingEquipmentClass}` && listItem.value.pricingEquipmentClass === 'XH'
                  ) || {}
                } else {
                  // Find the matching item
                  const matchingParent = hireDetailsFormValuesArr.find((listItem) =>
                    listItem.id === id
                  ) || {}
                  if (matchingParent) {
                    matchingFuelItemParent = matchingParent.equipment
                  }
                }
                const fuelItemTemplate = addFuelLineItem(item.sequenceNo, matchingFuelItemParent?.value)
                if (fuelItemTemplate) {
                  item.fuelItem.stockNumber = fuelItemTemplate.stockNumber || ""
                  item.fuelItem.equipmentDesc =
                    fuelItemTemplate.equipmentDesc || ""
                  item.fuelItem.unitCharge = fuelItemTemplate.unitCharge
                  item.fuelItem.chargeAmount =
                    item.fuelItem.quantity * fuelItemTemplate.unitCharge
                }
              }
              break
            case "quantity":
              item.quantity = value
              item.hireCharge = item.unitPrice ? (+item.quantity * +item.unitPrice).toString() : ""
              if (item.fuelItem && +value > 0) {
                item.fuelItem.quantity = +item.fuelItem.defaultQuantity * +value
                item.fuelItem.chargeAmount = +(
                  +item.fuelItem.quantity * (+item.fuelItem.unitCharge || 0)
                ).toFixed(2)
              }
              break
            case "unitPrice":
              item.unitPrice = value
              item.hireCharge = item.unitPrice ? (+item.quantity * +item.unitPrice).toString() : ""
              break
            case "hirePeriod":
              if (item.equipmentCategory !== "FUEL") {
                item.hirePeriod = value
                const pricingKey = hirePeriodPriceKeyMap[item.hirePeriod?.value] // 5week, 1 DAY ---> mapped key (e.g. pricingWeek, pricing1Day)
                item.unitPrice = item.equipment.value[pricingKey]?.toString()
                item.hireCharge = item.unitPrice ? (+item.quantity * +item.unitPrice).toString() : ""
              }
              break
            case "fuel-quantity":
              item.fuelItem.quantity = +value
              item.fuelItem.chargeAmount = +(
                +value * (+item.fuelItem.unitCharge || 0)
              ).toFixed(2)
              break
            case "fuel-unitCharge":
              item.fuelItem.unitCharge = value
              item.fuelItem.chargeAmount = +(
                (+item.fuelItem.quantity || 0) *
                (+item.fuelItem.unitCharge || 0)
              ).toFixed(2)
              break
            default:
              item[key] = value
          }

          return item
        }
        return item
      })
    })
  }

  const addOrDeleteHireDetailItem = (actionType: string, id?: number) => {
    setHIreDetailsFormValuesArr(prevArr => {
      switch (actionType) {
        case "add":
          const newItem = {
            ...initialHireDetailItem,
            id: uuidv4(),
          }
          return [...prevArr, newItem]
        case "delete":
          return prevArr.filter(item => item.id !== id)
      }
      return prevArr
    })
  }

  const initialOrderDetailsState: Record<string, any> = {
    contact: { label: "", value: null },
    orderPlacedVia: "",
    orderNumber: "",
    orderValue: "",
    enquiryType: { label: "", value: "" },
    notes: "",
  }

  const [orderDetailsFormValues, setOrderDetailsFormValues] = useState<
    Record<string, any>
  >(initialOrderDetailsState)

  const handleOrderDetailChange = (
    key: string,
    value: string | Record<string, any>
  ) => {
    setOrderDetailsFormValues({
      ...orderDetailsFormValues,
      [key]: value,
    })
  }

  const handleSiteSurveyAssignSurveyorCompleted = (siteSurveyNotes: string) => {
    setCustomerContract({
      ...customerContract,
      siteSurveyAssigned: true,
      siteSurveyNotes,
    })
  }

  const handleSiteSurveyCompleted = () => {
    setCustomerContract({
      ...customerContract,
      isSiteSurvey: false,
    })
  }

  const deliveryTimeString = resourceStringExtractor(
    "AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS"
  )

  const initialDeliveryDetailsState: Record<string, any> = {
    siteAddress: { label: "", value: null },
    siteContact: { label: "", value: null },
    depotId: "",
    depot: "",
    deliveryDate: null,
    deliveryTime: null,
    onHireDate: null,
    offHireDate: null,
    deliveryCharge: "",
    collectionCharge: "",
    satChargeBool: false,
    sunChargeBool: false,
    callFirst: false,
    callFirstDate: null,
  }

  const suppressEmailNotification = useRef(false);

  useEffect(() => {
    setIsContractLoading(true)
    getCustomerWithSavedContractsByContractId(accounts, instance, contractId)
      .then((results: any) => {
        setIsContractLoading(false)
        const { contracts = [] } =
          results?.data?.internalGetSavedContractsByContractId || {}

        const contract = contracts[0] || null

        if (!contract)
          navigate(`/cx-dashboard/customer/${customerId}/contracts/all-contracts`)

        const { items } = contract || {}

        const deliveryAndCollectionChargeItems = !items
          ? []
          : items.filter(
            ({
              equipmentCategory,
              stockNumber,
            }: {
              equipmentCategory: string
              stockNumber: string
            }) =>
              (equipmentCategory === "_TRSP" &&
                stockNumber === "_DELIVERY") ||
              (equipmentCategory === "_TRSP" && stockNumber === "_COLLECTION")
          )

        const deliveryCharge = deliveryAndCollectionChargeItems.find(
          ({ equipmentCategory, stockNumber }: Record<string, any>) =>
            equipmentCategory === "_TRSP" && stockNumber === "_DELIVERY"
        )?.chargeAmount

        const collectionCharge =
          deliveryAndCollectionChargeItems.find(
            ({ equipmentCategory, stockNumber }: Record<string, any>) =>
              equipmentCategory === "_TRSP" && stockNumber === "_COLLECTION"
          )?.chargeAmount || ""

        setDeliveryDetailsFormValues(prevState => {
          return {
            ...prevState,
            deliveryCharge: deliveryCharge
              ? parseFloat(deliveryCharge).toFixed(2) || "0"
              : "",
            collectionCharge: collectionCharge
              ? parseFloat(collectionCharge).toFixed(2) || "0"
              : "",
            satChargeBool: contract?.satCharge === "Y",
            sunChargeBool: contract?.sunCharge === "Y",
          }
        })
        checkTransportCharges(deliveryCharge, collectionCharge)
        setCustomerContract(contract)
        setShowBadDataMessage({ showMessage: false, message: "" })
        checkPostcodeIsExcluded(contract.sitePostCode)
        setContractDocumentsList()
        setLoadingStatus(prevStatus => ({
          ...prevStatus,
          contract: true,
        }))
      })
      .catch(err => {
        console.log(err)
        setIsContractLoading(false)
        setShowBadDataMessage({
          showMessage: true,
          message: `We could not find any contact data for Contract ID ${contractId}`,
        })
      })

    setIsEquipmentListLoading(true)
    getEquipmentList(accounts, instance, customerId)
      .then((results: any) => {
        const equipmentList =
          results?.data?.internalGetEquipmentListByCustomerId?.equipments || []
        setEquipmentList(equipmentList.map(transformEquipmentObjToOption))
        setIsEquipmentListLoading(false)
        setShowBadDataMessage({ showMessage: false, message: "" })
        setLoadingStatus(prevStatus => ({
          ...prevStatus,
          equipment: true,
        }))
      })
      .catch(err => {
        console.log(err)
        setIsEquipmentListLoading(false)
        setShowBadDataMessage({
          showMessage: true,
          message: `We could not fetch equipment data.`,
        })
      })

    getSitesForCustomerSavedContractsByCustomerId(
      accounts,
      instance,
      customerId
    )
      .then((results: any) => {
        setDeliverySites(
          results?.data?.internalSitesForCustomer.map(transformSiteObjToOption)
        )
        setShowBadDataMessage({ showMessage: false, message: "" })
        setLoadingStatus(prevStatus => ({
          ...prevStatus,
          sites: true,
        }))
      })
      .catch(err => {
        console.log(err)
        setShowBadDataMessage({
          showMessage: true,
          message: `We could not find any site data for Customer ID ${customerId}`,
        })
      })

    getContactsByCustomerId(accounts, instance, +customerId)
      .then(result => {
        if (result?.data?.internalGetContactsByCustomerId?.contacts?.length) {
          setOrderContacts(
            result.data.internalGetContactsByCustomerId.contacts.map(
              transformContactObjToOption
            )
          )
          setShowBadDataMessage({ showMessage: false, message: "" })
          setLoadingStatus(prevStatus => ({
            ...prevStatus,
            contacts: true,
          }))
        } else {
          setShowBadDataMessage({
            showMessage: true,
            message: `We could not find any contact data for Customer ID ${customerId}`,
          })
        }
      })
      .catch(err => {
        console.log(err)
        setShowBadDataMessage({
          showMessage: true,
          message: `Failed to fetch contact data for Customer ID ${customerId}`,
        })
      })

    getCustomerPropertiesByCustomerId(accounts, instance, customerId)
      .then(result => {
        setPurchaseOrderNoValidation(result?.data?.internalGetCustomerProperties || null)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (loadingStatus.contract && loadingStatus.equipment && loadingStatus.sites && loadingStatus.contacts) {
      // Everything has finished loading
      initialRenderHirePeriod.current = false
    }
  }, [loadingStatus])

  useEffect(() => {
    if (customerContract && equipmentList.length > 0) {
      const { items } = customerContract || {}

      const removeNonEquipmentItems = ({
        equipmentCategory,
        stockNumber,
        parentItemSeq,
      }: Record<string, any>) =>
        !(equipmentCategory === "_TRSP" && stockNumber === "_DELIVERY") &&
        !(equipmentCategory === "_TRSP" && stockNumber === "_COLLECTION") &&
        equipmentCategory !== "_INS" &&
        !(equipmentCategory === "FUEL" && !!parentItemSeq)

      const productItems = !items ? [] : items.filter(removeNonEquipmentItems)
      const fuelItems = !items
        ? []
        : items.filter(
          (item: Record<string, any>) => item.equipmentCategory === "FUEL"
        )

      const trasformedProductItems = !productItems.length
        ? [initialHireDetailItem]
        : productItems.map(
          ({
            equipmentDesc,
            equipmentCategory,
            stockNumber,
            chargeAmount,
            chargePeriod,
            sequenceNo,
            unitCharge,
            supplierPO,
            supplierPOIsSent,
            supplierName,
            supplierContactTelephone,
          }: Record<string, any>) => {
            const hirePeriod =
              equipmentCategory === "FUEL"
                ? { label: chargePeriod, value: chargePeriod }
                : hirePeriodOptions.find(
                  option => option.value === chargePeriod
                )

            const equipment = equipmentList.find(
              option =>
                option?.value?.pricingEquipmentClass ===
                equipmentCategory && option?.value?.pcode === stockNumber ||
                option?.value?.pcode === `XH${equipmentCategory}` && option?.value?.pricingEquipmentClass === 'XH'
            )

            const transformedProductItem: Record<string, any> = {
              id: uuidv4(),
              sequenceNo,
              description: equipmentDesc,
              equipment,
              quantity: "1",
              hireCharge: chargeAmount
                ? parseFloat(chargeAmount).toFixed(2) // actually multiplied by quantity here, but quantity for equipment line item will be always one when data is loaded. so, just using chargeAmount here.
                : "",
              hirePeriod,
              unitPrice: unitCharge ? parseFloat(unitCharge).toFixed(2) : "",
              xh: equipment?.value?.pricingEquipmentClass === 'XH',
              supplierPO,
              supplierPOIsSent,
              supplierName,
              supplierContactTelephone,
            }

            const matchedFuelItem = fuelItems.find(
              (item: Record<string, any>) => item.parentItemSeq === sequenceNo
            )

            if (matchedFuelItem) {
              transformedProductItem.fuelItem = {
                stockNumber: matchedFuelItem.stockNumber,
                sequenceNo: +matchedFuelItem.sequenceNo,
                chargeAmount: +matchedFuelItem.chargeAmount,
                unitCharge: equipment?.value?.fuelChargePerUnit.toFixed(2),
                quantity: parseFloat(
                  (matchedFuelItem.chargeAmount / equipment?.value?.fuelChargePerUnit).toFixed(2)
                ),
                defaultQuantity: parseFloat(
                  (matchedFuelItem.chargeAmount / equipment?.value?.fuelChargePerUnit).toFixed(2)
                ),
                equipmentDesc: matchedFuelItem.equipmentDesc,
                equipmentCategory: matchedFuelItem.equipmentCategory,
              }
            }

            return transformedProductItem
          }
        )

      setHIreDetailsFormValuesArr(trasformedProductItems)
      calculateAdditionalTransportCharges(trasformedProductItems)
    }
  }, [customerContract, equipmentList])

  useEffect(() => {
    if (customerContract && deliverySites?.length > 0) {
      const { siteId, autoOffhire } = customerContract || {}

      setDeliveryDetailsFormValues(prevState => {
        return {
          ...prevState,
          siteAddress: deliverySites.find(
            (siteObj: Record<string, any>) =>
              siteObj?.value?.addressId === siteId
          ),
          callFirst: autoOffhire !== "Y",
        }
      })
    }
  }, [customerContract, deliverySites])

  useEffect(() => {
    if (customerContract && orderContacts.length > 0) {
      const { customerContactId, orderNo, orderValue, deliveryNotes } =
        customerContract || {}

      const contact = orderContacts.find(
        ({ value }: Record<string, any>) =>
          +value?.contactId === customerContactId
      )

      const notes = getSplitDeliveryNotesAtIndex(deliveryNotes, 1)

      setOrderDetailsFormValues(prevState => ({
        ...prevState,
        contact:
          contact || orderContacts[0] || initialOrderDetailsState.contact,
        orderNumber: orderNo || "",
        orderValue: orderValue || "",
        orderPlacedVia: customerContract.orderPlacedVia || "",
        enquiryType: {
          value: customerContract.enquiryType || "",
          label: customerContract.enquiryType || "",
        },
        notes,
      }))
    }
  }, [customerContract, orderContacts])

  const [deliveryDetailsFormValues, setDeliveryDetailsFormValues] = useState<
    Record<string, any>
  >(initialDeliveryDetailsState)

  const deliveryTimeOptions = [
    { label: deliveryTimeString("DELIVERYTIME1"), value: "17:00" },
    { label: deliveryTimeString("DELIVERYTIME2"), value: "08:00" },
    { label: deliveryTimeString("DELIVERYTIME3"), value: "08:30" },
    { label: deliveryTimeString("DELIVERYTIME4"), value: "09:00" },
    { label: deliveryTimeString("DELIVERYTIME5"), value: "09:30" },
    { label: deliveryTimeString("DELIVERYTIME6"), value: "10:00" },
    { label: deliveryTimeString("DELIVERYTIME7"), value: "10:30" },
    { label: deliveryTimeString("DELIVERYTIME8"), value: "11:00" },
    { label: deliveryTimeString("DELIVERYTIME9"), value: "11:30" },
    { label: deliveryTimeString("DELIVERYTIME10"), value: "12:00" },
    { label: deliveryTimeString("DELIVERYTIME11"), value: "12:30" },
    { label: deliveryTimeString("DELIVERYTIME12"), value: "13:00" },
    { label: deliveryTimeString("DELIVERYTIME13"), value: "14:00" },
    { label: deliveryTimeString("DELIVERYTIME14"), value: "15:00" },
    { label: deliveryTimeString("DELIVERYTIME15"), value: "16:00" },
    { label: deliveryTimeString("DELIVERYTIME16"), value: "17:00" },
  ]

  useEffect(() => {
    const addressId = deliveryDetailsFormValues?.siteAddress?.value?.addressId
    if (customerId && addressId) {
      getContactsForSiteBySiteId(accounts, instance, +customerId, +addressId)
        .then((results: any) => {
          const selectedContacts =
            results?.data?.internalGetContactsForSiteBySiteId?.contacts.map(
              transformContactObjToOption
            )
          setDeliveryContacts(selectedContacts)
          const { delContactId } = customerContract || {}
          const siteContact = selectedContacts.find(
            ({ value }: Record<string, any>) =>
              +value?.contactId === delContactId
          )
          const { deliveryDate, deliveryTime, hireStartDate, plannedEndDate, callFirstDate } =
            customerContract || {}

          setDeliveryDetailsFormValues(prevState => ({
            ...prevState,
            siteContact:
              siteContact ||
              selectedContacts[0] ||
              initialDeliveryDetailsState.siteContact,
            deliveryDate: deliveryDate ? new Date(deliveryDate) : null,
            deliveryTime,
            onHireDate: hireStartDate ? new Date(hireStartDate) : null,
            offHireDate: plannedEndDate ? new Date(plannedEndDate) : null,
            callFirstDate: callFirstDate ? new Date(callFirstDate) : null,
          }))
          setShowBadDataMessage({ showMessage: false, message: "" })
        })
        .catch(err => {
          console.log(err)
          setShowBadDataMessage({
            showMessage: true,
            message: `Failed to fetch contact data for site ID ${addressId}`,
          })
        })
    } else {
      setDeliveryContacts(orderContacts)

      setDeliveryDetailsFormValues(prevState => ({
        ...prevState,
        siteContact: initialDeliveryDetailsState.siteContact,
      }))
    }
  }, [deliveryDetailsFormValues?.siteAddress?.value?.addressId])

  const getPreviousExclusionPostcodeTransportCharges = (outerPostcode: string) => {
    if (outerPostcode && !previousExcludedPostcodeTransportCharges.hasOwnProperty(outerPostcode)) {
      // Nope, get any transport charges for the new postcode and add it to the previous transport charges
      getPreviousExclusionPostcodeTransportChargesByCustomer(accounts, instance, customerId, outerPostcode, contractId)
        .then((results: Record<string, any>) => {
          const charges = results?.data?.internalGetPreviousExclusionPostcodeTransportChargesByCustomer?.charges || []
          setPreviousExcludedPostcodeTransportCharges({
            ...previousExcludedPostcodeTransportCharges,
            [outerPostcode]: charges,
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  const checkPostcodeIsExcluded = (postcode: string) => {
    const isPostcodeExcludedResult = isPostcodeExcluded(excludedPostcodes, postcode)
    setExcludedPostcode(isPostcodeExcludedResult)

    if (isPostcodeExcludedResult.excluded) {
      getPreviousExclusionPostcodeTransportCharges(isPostcodeExcludedResult.outerPostcode || "")
    }
  }

  const handleDeliveryDetailChange = (
    key: string,
    value: string | boolean | number | Record<string, any> | null
  ) => {
    let formValues: Record<string, any>
    if (key === "transportCharge") {
      formValues = {
        ...deliveryDetailsFormValues,
        deliveryCharge: value,
        collectionCharge: value,
      }
    } else {
      formValues = {
        ...deliveryDetailsFormValues,
        [key]: value,
      }
    }
    if (key === "offHireDate") {
      formValues = {
        ...formValues,
        callFirst: value === null
      }
    }

    checkTransportCharges(formValues.deliveryCharge, formValues.collectionCharge)
    setDeliveryDetailsFormValues(formValues)
    checkPostcodeIsExcluded(formValues.siteAddress?.value?.addressPostcode || "")
  }

  const calculateAdditionalTransportCharges = (hireItems: any) => {
    let hasStandardProduct = false

    const additionalTransportCharges = hireItems.reduce((acc: any, curr: Record<string, any>) => {
      const tier = curr.equipment?.value?.additionalTransportChargeTier
      if (tier) {
        let matchingTier = acc.find((t: any) => t.key === tier)
        const productLabel = curr.equipment.value.pricingEquipmentClass === 'XH' ? curr.equipment.value.pcode : curr.equipment.value.pricingEquipmentClass
        if (!matchingTier) {
          acc.push({
            key: tier,
            charge: curr.equipment.value.additionalTransportCharge,
            products: [productLabel]
          })
        } else {
          if (!matchingTier.products.includes(productLabel)) {
            matchingTier.products.push(productLabel)
          }
        }
      } else {
        hasStandardProduct = hasStandardProduct || curr.equipment?.value ? true : false
      }

      return acc
    }, [])

    setAdditionalTransport(additionalTransportCharges)

    return {
      additionalTransportCharges,
      hasStandardProduct
    }
  }

  useEffect(() => {
    if (showBadDataMessage.showMessage) {
      console.log(showBadDataMessage.message)
    }
  }, [showBadDataMessage])

  useEffect(() => {
    const { additionalTransportCharges, hasStandardProduct } = calculateAdditionalTransportCharges(hireDetailsFormValuesArr)

    // First sort out the depot based on all items having the "XH" box checked
    const allItemsCrossHire = hireDetailsFormValuesArr.every(item => item.xh)
    const depotId = allItemsCrossHire ? "5" : "4"
    const depot = allItemsCrossHire ? "Rehire" : "Head Office"

    let formValues: any = {
      ...deliveryDetailsFormValues,
      depotId,
      depot,
    }

    if (hireDetailsFormValuesArr[0]?.equipment?.value) {
      setDisableAddHireDetailItem(false)

      if (!initialRenderHirePeriod.current) {
        // Only run if the initial render has finished.
        const lowestHIrePeriodStr = hireDetailsFormValuesArr
          .reduce((prev: any, curr: any) => {
            if (prev?.equipment?.value?.pricingEquipmentClass === "FUEL") {
              return curr
            }
            if (curr?.equipment?.value?.pricingEquipmentClass === "FUEL") {
              return prev
            }
            return Number(prev.hirePeriod.value.charAt(0)) <
              Number(curr.hirePeriod.value.charAt(0))
              ? prev
              : curr
          })
          ?.hirePeriod.value.charAt(0)

        setLowestHirePeriod(Number(lowestHIrePeriodStr))

        // Break out any additional transport charges and check if we have any standard products.
        let standardDelivery = parseFloat(hasStandardProduct ? customer.transportRate || "0" : "0")
        let standardCollection = parseFloat(hasStandardProduct ? customer.transportRate || "0" : "0")

        // Calculate the additional charges.
        const additionalCharges = additionalTransportCharges.reduce((acc: number, curr: any) => {
          return acc + curr.charge
        }, 0.00)

        if (additionalCharges === 0) {
          // There are no additional charges, so if the current values for delivery/collection are
          // different to the standard transport rate then leave them as they are.
          if (!customer.transportRate || deliveryDetailsFormValues.deliveryCharge !== customer.transportRate) {
            standardDelivery = parseFloat(deliveryDetailsFormValues.deliveryCharge)
          }

          if (!customer.transportRate || deliveryDetailsFormValues.collectionCharge !== customer.transportRate) {
            standardCollection = parseFloat(deliveryDetailsFormValues.collectionCharge)
          }
        }

        // Do we have at least one item with additional transport and a standard product, but no customer transport rate...
        setIsZeroCustomerTransportRateWarningVisible(!customer.transportRate && hasStandardProduct)


        // Set the combined standard and additional charges.
        formValues = {
          ...formValues,
          deliveryCharge: (standardDelivery + additionalCharges).toFixed(2),
          collectionCharge: (standardCollection + additionalCharges).toFixed(2),
        }
      }
    } else {
      // There are no hire items, so default to either the contract transport charges or
      // the standard customer rate.
      setDisableAddHireDetailItem(true)

      const deliveryCharge = customerContract?.items.find(
        ({ equipmentCategory, stockNumber }: Record<string, any>) =>
          equipmentCategory === "_TRSP" && stockNumber === "_DELIVERY"
      )?.chargeAmount || ""

      const collectionCharge = customerContract?.items.find(
        ({ equipmentCategory, stockNumber }: Record<string, any>) =>
          equipmentCategory === "_TRSP" && stockNumber === "_COLLECTION"
      )?.chargeAmount || ""

      formValues = {
        ...formValues,
        deliveryCharge: parseFloat(deliveryCharge || customer?.transportRate || "0").toFixed(2),
        collectionCharge: parseFloat(collectionCharge || customer?.transportRate || "0").toFixed(2),
      }
    }

    // Set any changes made to the delivery details.
    setDeliveryDetailsFormValues(formValues)
    checkTransportCharges(formValues.deliveryCharge, formValues.collectionCharge)
  }, [hireDetailsFormValuesArr])

  useEffect(() => {
    if (deliveryDetailsFormValues?.onHireDate && lowestHirePeriod) {
      let offhireAutoDate = new Date(deliveryDetailsFormValues.onHireDate)
      if (lowestHirePeriod === 5 || isNaN(+lowestHirePeriod)) {
        if (deliveryDetailsFormValues.offHireDate) {
          // Only clear the offhire date if it's less than 5 days into the future
          const momentOnHireDate = moment(deliveryDetailsFormValues.onHireDate)
          const momentOffHireDate = moment(deliveryDetailsFormValues.offHireDate)

          if (momentOffHireDate.diff(momentOnHireDate, "days") <= 5) {
            handleDeliveryDetailChange("offHireDate", null)
          }
        }
      } else {
        offhireAutoDate.setDate(
          deliveryDetailsFormValues.onHireDate.getDate() +
          (+lowestHirePeriod - 1)
        )
        handleDeliveryDetailChange("offHireDate", offhireAutoDate)
      }
    }
  }, [lowestHirePeriod, deliveryDetailsFormValues?.onHireDate])

  const handleOnClick = (type: string) => () => {
    // cancel, save, confirm
    switch (type) {
      case "cancel":
        setModalState({ mode: "cancel", isOpen: true })
        break
      case "save":
        handleSubmitContract("save")
        break
      case "confirm":
        setModalState({ mode: "confirm", isOpen: true })
        break
      case "cancelContract": {
        setModalState({ mode: "cancelContract", isOpen: true })
        getCancellationReasons()
        break
      }
      case "duplicateContract": {
        setModalState({ mode: "duplicateContract", isOpen: true })
        break
      }
    }
  }

  const getCancellationReasons = () => {
    getContractCancellationReasons(accounts, instance)
      .then(result => {
        setCancellationReasons(result?.data?.internalGetContractCancellationReasons.reasons || [])
      })
      .catch(err => {
        console.log(err)
      })
  }

  const itemsAdded = hireDetailsFormValuesArr.filter(item => {
    return item?.equipment?.value?.pcode || item.quantity > 0
  })
  const itemsStr = itemsAdded
    .map(item => {
      const { equipment, quantity } = item || {}
      const { pcode } = equipment?.value || {}
      const catcode = equipment?.value.pricingEquipmentClass || ""
      return `${quantity} x ${catcode} ${pcode}`
    })
    .join(", ")

  const { deliveryDate, deliveryTime, offHireDate, onHireDate } =
    deliveryDetailsFormValues || {}

  const deliveryTimeStr =
    deliveryTimeOptions.find(({ value }) => value === deliveryTime)
      ?.label || deliveryTime || ""

  const automaticDeliveryNotes =
    itemsAdded.length > 0
      ? `Please deliver ${itemsStr} ${deliveryDate ? `on ${deliveryDate.toDateString()}` : ""
      }${deliveryTimeStr ? `, ${deliveryTimeStr}` : ""}.
        ${onHireDate ? `Hire to start on ${onHireDate.toDateString()}` : ""}. ${offHireDate ? ` OFF HIRE on ${offHireDate.toDateString()}.` : ""
      } Thank you.`
      : ""

  const generateConfirmationPDF = async (updatedContract: Record<string, any>, orderContactData: Record<string, any>) => {
    let contractConfirmationEquipmentItems: IContractConfirmationItem[] = []
    let contractConfirmationFuelItems: IContractConfirmationItem[] = []
    let totalFuelCharge: number = 0
    const equipmentHireCharge: number = updatedContract.items.reduce((total: number, item: any) => {
      contractConfirmationEquipmentItems.push({
        chargeAmount: parseFloat(item.chargeAmount) * parseFloat(item.quantity),
        equipmentCategory: item.equipmentCategory,
        equipmentDescription: item.equipmentDesc,
        quantity: item.quantity,
        stockNumber: item.stockNumber
      })
      if (item.fuelItem) {
        totalFuelCharge += parseFloat(item.fuelItem.unitCharge) * parseFloat(item.fuelItem.quantity)
        contractConfirmationFuelItems.push({
          chargeAmount: parseFloat(item.fuelItem.unitCharge) * parseFloat(item.fuelItem.quantity),
          equipmentCategory: item.fuelItem.equipmentCategory,
          equipmentDescription: item.fuelItem.equipmentDesc,
          quantity: item.fuelItem.quantity,
          stockNumber: item.fuelItem.stockNumber
        })
      }
      // Only include equipment charges in the hire total, not fuel
      if (item.equipmentCategory !== 'FUEL' && item.chargeAmount) {
        total += (parseFloat(item.chargeAmount) * parseFloat(item.quantity))
      }
      return total
    }, 0)

    let collectionCharge = updatedContract.collectionCharge ? parseFloat(updatedContract.collectionCharge) : parseFloat(updatedContract.deliveryCharge)
    collectionCharge = isNaN(collectionCharge) ? 0 : collectionCharge
    let deliveryCharge = parseFloat(updatedContract.deliveryCharge)
    deliveryCharge = isNaN(deliveryCharge) ? 0 : deliveryCharge

    const siteContact = updatedContract.delContact && `${updatedContract.delContact?.contactFirstName || ''}${updatedContract.delContact?.contactFirstName ? ' ' : ''}${updatedContract.delContact?.contactSurname || ''} (${updatedContract.delContact?.contactMobile ? `${updatedContract.delContact.contactMobile}` : ''}${updatedContract.delContact?.contactMobile && updatedContract.delContact?.contactTelephone ? ', ' : ''}${updatedContract.delContact?.contactTelephone ? `${updatedContract.delContact.contactTelephone}` : ''})`

    // Temporary fix because the summary doesn't calculate anything other than qty x unit price
    // If there is a 5WEEK for any item, change wording to "total weekly charge"
    const isWeeklyCharge = !!updatedContract.items.find((item: Record<string, any>) => {
      return item.chargePeriod === "5WEEK"
    })

    const isMonthlyCharge = !!updatedContract.items.find((item: Record<string, any>) => {
      return item.chargePeriod === "MONTH"
    })

    let orderValue = equipmentHireCharge

    if (!isWeeklyCharge && !isMonthlyCharge) {
      orderValue += deliveryCharge + collectionCharge
    }

    const confirmedContract: IContractConfirmation = {
      accidentalDamageWaiverCharge: 0,
      accidentalDamageWaiverPercentage: customer.accidentalDamageWaiverPercentage,
      collectionCharge,
      contractNo: customerContract?.contractNo,
      contractType: contractType === 'Firm Booking' ? 'order' : contractType.toLowerCase(),
      customerFirstName: orderContactData.contactFirstName,
      deliveryCharge,
      hasInsurance: hasInsurance === 'true',
      hireStartDate: moment(updatedContract.hireStartDate).format('YYYY-MM-DD'),
      contractItems: contractConfirmationEquipmentItems,
      fuelItems: contractConfirmationFuelItems,
      orderNo: updatedContract.orderNo,
      orderValue: orderValue,
      plannedEndDate: updatedContract.plannedEndDate ? moment(updatedContract.plannedEndDate).format('YYYY-MM-DD') : 'Open Hire',
      siteName: updatedContract.siteAddressData.addressName,
      siteAddressLine1: updatedContract.siteAddressData.addressLine1,
      siteAddressLine2: updatedContract.siteAddressData.addressLine2,
      siteAddressLine3: updatedContract.siteAddressData.addressLine3,
      siteContact,
      siteTown: updatedContract.siteAddressData.addressTown,
      siteCounty: updatedContract.siteAddressData.addressCounty,
      sitePostCode: updatedContract.siteAddressData.addressPostcode,
      theftAndLossWaiverCharge: 0,
      theftAndLossWaiverPercentage: customer.theftAndLossWaiverPercentage,
      documentTitle: contractType === 'Firm Booking' ? 'Confirmation' : contractType,
      isWeeklyCharge,
      isMonthlyCharge,
      totalFuelCharge,
      deliveryDate: updatedContract.deliveryDate && updatedContract.deliveryDate !== updatedContract.hireStartDate
        ? moment(updatedContract.deliveryDate).format('YYYY-MM-DD')
        : '',
      deliverySlot: deliveryTimeStr,
    }

    // And insurance waivers if required
    if (!confirmedContract.hasInsurance) {
      if (!!theftAndLossWaiverPercentage) {
        confirmedContract.theftAndLossWaiverCharge = equipmentHireCharge * (theftAndLossWaiverPercentage / 100)
        confirmedContract.orderValue += confirmedContract.theftAndLossWaiverCharge
      }
      if (!!accidentalDamageWaiverPercentage) {
        confirmedContract.accidentalDamageWaiverCharge = equipmentHireCharge * (accidentalDamageWaiverPercentage / 100)
        confirmedContract.orderValue += confirmedContract.accidentalDamageWaiverCharge
      }
    }

    // Generate the PDF, upload it to storage and tell the middleware
    const blob = await pdf(<ContractConfirmationPDF contract={confirmedContract} />).toBlob();
    const file = new File([blob], `${customerContract?.contractNo} ${confirmedContract.documentTitle}.pdf`)

    const metadata = {
      fileName: file.name,
      contractId: contractId,
      uploadedBy: uploadedBy,
    }

    await uploadToBlobClient(file, metadata, accounts, instance);
  }

  const handleConfirmationModalConfirm = () => {
    switch (modalState.mode) {
      case "confirm":
        handleSubmitContract(modalState.mode)
        break
      case "cancelContract":
        handleCancelContract()
        break
      case "duplicateContract":
        handleDuplicateContract()
        break
    }
  }

  const handleSubmitContract = (mode: string) => {
    const isConfirmation = mode === "confirm"
    const {
      siteAddress,
      siteContact,
      depotId,
      deliveryDate,
      deliveryTime,
      onHireDate,
      offHireDate,
      deliveryCharge,
      collectionCharge,
      satChargeBool,
      sunChargeBool,
      callFirst,
      callFirstDate,
    } = deliveryDetailsFormValues || {}

    const { value: siteAddressData } = siteAddress || {}
    const { value: siteContactData } = siteContact || {}
    const {
      contact: orderContactObj,
      enquiryType: enquiryTypeObj,
      notes,
      orderNumber,
      orderValue,
      orderPlacedVia,
    } = orderDetailsFormValues || {}
    const { value: orderContactData } = orderContactObj || {}
    const { value: enquiryType } = enquiryTypeObj || {}

    const itemsWithQuantity = hireDetailsFormValuesArr.filter(item => {
      const { equipment, quantity } = item || {}
      const { pricingEquipmentClass, pcode } = equipment?.value || {}
      return pricingEquipmentClass && pcode && quantity && +quantity > 0
    })

    const items = itemsWithQuantity.map(item => {
      const {
        sequenceNo,
        equipment,
        description,
        quantity,
        // hireCharge,  // chargeAmount Just use unitPrice (which includes day charge here) as item can only saved one by one by syrinx.
        xh,
        unitPrice,
        hirePeriod,
        fuelItem,
      } = item || {}

      const { pricingEquipmentClass, pcode } = equipment?.value || {}

      const preparedItem: Record<string, any> = {
        contractID: +customerContract?.contractId, // required
        sequenceNo: +sequenceNo ? +sequenceNo : null, // required
        equipmentCategory: pricingEquipmentClass, // required on post
        stockNumber: pcode, // required on post
        equipmentDesc: description,
        xh,
        quantity: +quantity,
        unitCharge: unitPrice,
        chargePeriod: hirePeriod?.value,
        chargeAmount: unitPrice, // keep this as unit price as product items with multiple quantity will be separated by the quantity in the middleware.
      }

      if (fuelItem) {
        const clonedFuelItem = JSON.parse(JSON.stringify(fuelItem))
        clonedFuelItem.sequenceNo = +clonedFuelItem.sequenceNo
        clonedFuelItem.unitCharge = +clonedFuelItem.unitCharge
        delete clonedFuelItem.defaultQuantity

        preparedItem.fuelItem = clonedFuelItem
      }

      return preparedItem
    })

    const getContractTypeObj = (contractType: string) => {
      switch (contractType) {
        case "Quotation":
          return {
            provisionalBooking: false,
            isQuote: true,
          }
        case "Provisional Booking":
          return {
            provisionalBooking: true,
            isQuote: false,
          }
        case "Firm Booking":
          return {
            provisionalBooking: false,
            isQuote: false,
          }
      }
    }

    const contractTypeObj = getContractTypeObj(contractType)

    const updatedContract: Record<string, any> = {
      contractID: +customerContract?.contractId, // required
      customerId: +customerId, // required
      contact: orderContactData
        ? `${orderContactData?.contactFirstName} ${orderContactData?.contactSurname}`.substring(0, 30)
        : "", // required
      contractStatus: isConfirmation ? "D" : customerContract?.contractStatus,
      contractType,
      ...contractTypeObj,

      // delivery
      siteId: +siteAddressData?.addressId || null, // required
      siteAddressData: siteAddressData || null,
      delContactID: +siteContactData?.contactId || null,
      delContact: siteContactData || null,
      contractDepotId: +depotId, // required
      deliveryDate: deliveryDate
        ? getLocalMidnightISOString(deliveryDate)
        : "",
      promisedDeliveryDate: deliveryDate
        ? getLocalMidnightISOString(deliveryDate)
        : "",
      deliveryTime,

      hireStartDate: onHireDate
        ? getLocalMidnightISOString(onHireDate)
        : "",
      plannedEndDate: offHireDate
        ? getLocalMidnightISOString(offHireDate)
        : "",
      deliveryCharge,
      collectionCharge,
      satCharge: satChargeBool ? "Y" : "NULL",
      sunCharge: sunChargeBool ? "Y" : "NULL",
      autoOffhire: callFirst ? "NULL" : "Y",
      callFirstDate: callFirstDate
        ? getLocalMidnightISOString(callFirstDate)
        : "",

      // order details
      orderContactID: +orderContactData?.contactId || null,
      orderPlacedVia,
      enquiryType,
      deliveryNotes: `${automaticDeliveryNotes}${notes ? "####" : ""}${notes}`.trim(),
      orderNo: orderNumber,
      orderValue,
      items,
    }

    if (siteAddressData?.update) {
      const clonedSiteAddressData = JSON.parse(JSON.stringify(siteAddressData))
      delete clonedSiteAddressData.update
      updatedContract.siteAddressData = clonedSiteAddressData
      updatedContract.updatedSiteAddressData = clonedSiteAddressData
    }

    if (siteContactData?.update) {
      const clonedContactData = JSON.parse(JSON.stringify(siteContactData))
      delete clonedContactData.update
      updatedContract.delContact = clonedContactData
      updatedContract.updatedDelContactData = clonedContactData
    }

    if (orderContactData?.update) {
      const clonedOrderContactData = JSON.parse(
        JSON.stringify(orderContactData)
      )
      delete clonedOrderContactData.update
      updatedContract.updatedOrderContactData = clonedOrderContactData
    }

    const requiredFieldsForSave = [
      "contractID",
      "siteId",
      "customerId",
      "contractDepotId",
    ]

    const requiredFieldsForConfirmation = [
      ...requiredFieldsForSave,
      "orderNo",
      "items",
      "deliveryDate",
      "hireStartDate",
      "deliveryTime",
    ]

    const requiredFields = isConfirmation
      ? requiredFieldsForConfirmation
      : requiredFieldsForSave

    const errors = requiredFields.reduce((agg: string[], key: string) => {
      if (key === "items" && !items.length) {
        return [...agg, "Please add an item to the contract."]
      }
      if (key === "hireStartDate") {
        // On Hire Date
        if (!updatedContract[key]) return [...agg, "On Hire Date is required."]
      }
      if (key === "deliveryDate") {
        // Delivery Date
        if (!updatedContract[key]) return [...agg, "Delivery Date is required."]
      }
      if (key === "deliveryTime") {
        // Delivery Date
        if (!updatedContract[key]) return [...agg, "Delivery Time is required."]
      }
      if (key === "siteId") {
        return !updatedContract[key] && !updatedContract?.updatedSiteAddressData
          ? [...agg, "Please select site address."]
          : agg
      }
      if (!updatedContract[key]) {
        let errorMessage = `${key} is required.`
        if (key === "orderNo") errorMessage = "Please enter Order Number."
        return [...agg, errorMessage]
      }
      return agg
    }, [])

    setFormStatus({
      status: "error",
      messages: errors,
    })

    if (errors.length) {
      closeModal()
      return null
    }

    updatedContract.orderSummary = summaryRef?.current?.innerText

    updatedContract.confirm = isConfirmation

    if (mode === "confirm") {
      updatedContract.suppressEmailNotification = suppressEmailNotification.current
    }

    setIsSaving(true)

    saveContract(accounts, instance, updatedContract)
      .then((results: Record<string, any>) => {
        setIsSaving(false)
        const contractId = results?.data?.internalSaveContract?.contractId

        if (contractId) {
          setFormStatus({
            status: "success",
            messages: [
              mode === "confirmation"
                ? "contract confirmed successfully"
                : "Changes saved successfully",
            ],
          })
          if (isConfirmation) {
            // Create the confirmation PDF
            setIsSaving(true)
            generateConfirmationPDF(updatedContract, orderContactData)
              .then(() => {
                setIsSaving(false)
              })
              .finally(() => {
                navigate(`/cx-dashboard/customer/${customerId}/contracts/all-contracts`)
              })
          }
        } else {
          const errorMessage = results?.errors[0].message
          setFormStatus({
            status: "error",
            messages: [
              isConfirmation
                ? errorMessage
                  ? `Confirmation was unsuccessful. ${errorMessage}`
                  : "Confirmation was unsuccessful. Please try again."
                : "Save was unsuccessful. Please try again.",
            ],
          })
          if (isConfirmation) setModalState({ mode: "confirm", isOpen: false })
        }
      })
      .catch(err => {
        console.log(err)
        setIsSaving(false)
        setFormStatus({
          status: "error",
          messages: [
            mode === "confirmation"
              ? "Confirmation was unsuccessful. Please try again."
              : "Save was unsuccessful. Please try again.",
          ],
        })
        if (isConfirmation) setModalState({ mode: "confirm", isOpen: false })
      })
  }

  const handleCancelContract = () => {
    setModalState({ mode: "cancelContract", isOpen: false })
    setIsCancelling(true)
    setIsSaving(true)
    const input = {
      customerId: +customerId,
      contractId: +contractId,
      reason: cancellationReason,
    }
    cancelContract(accounts, instance, input)
      .then((results: Record<string, any>) => {
        setIsCancelling(false)
        setIsSaving(false)
        const success = results?.data?.internalCancelContract?.success
        if (success) {
          navigate(`/cx-dashboard/customer/${customerId}/contracts/all-contracts`)
        } else {
          setFormStatus({
            status: "error",
            messages: ["Cancellation was unsuccessful. Please try again."],
          })
        }
      })
  }

  const handleDuplicateContract = () => {
    setModalState({ mode: "duplicateContract", isOpen: false })
    setIsDuplicating(true)
    setIsSaving(true)

    duplicateContract(accounts, instance, +contractId)
      .then((results: Record<string, any>) => {
        setIsDuplicating(false)
        setIsSaving(false)
        const { duplicatedContractId, errorMessage } = results?.data?.internalDuplicateContract
        if (duplicatedContractId) {
          // Should use navigate, but it only changes URL and doesn't reload the duplicated contract :-(
          window.location.href = `/cx-dashboard/customer/${customerId}/contracts/saved/${duplicatedContractId}`
        } else {
          setFormStatus({
            status: "error",
            messages: ["Duplication was unsuccessful.", errorMessage],
          })
        }
      })
  }

  const handleProformaInvoice = async () => {
    contractDocuments.find((doc) => doc.startsWith(`${customerContract?.contractNo} Proforma Invoice`))
      ? setHasExistingProforma(true)
      : setHasExistingProforma(false)

    setIsProformaInvoicePDFModalOpen(true)
  }

  const confirmProformaInvoicePDF = async () => {
    setIsProformaInvoicePDFModalOpen(false)
    setIsCreatingProforma(true)

    getProformaInvoice(accounts, instance, +contractId)
      .then(async (results: Record<string, any>) => {
        
        const { 
          success, 
          errorMessage, 
          header, 
          items, 
          customerAddress, 
          siteAddress 
        } = results?.data?.internalGetProformaInvoice
        
        if (success) {
          const proformaInvoice: IProformaInvoice = {
            header,
            items,
            customerAddress,
            siteAddress,
          }

          await generateProformaInvoicePDF(proformaInvoice)
            .then(() => {
              setIsCreatingProforma(false)
            })
            .finally(async () => {
              await new Promise(resolve => setTimeout(resolve, 5000))
                .then(async () => {
                  setContractDocumentsList()
                })
            })

        } else {
          setFormStatus({
            status: "error",
            messages: [
              "Unable to create the proforma invoice.", 
              errorMessage || "There was a problem creating the proforma invoice."
            ],
          })
        }
      })
  }

  const generateProformaInvoicePDF = async(proformaInvoice: IProformaInvoice) => {
    // Generate the PDF, upload it to storage and tell the middleware
    const blob = await pdf(<ProformaInvoicePDF invoice={proformaInvoice} resourceStrings={invoiceResourceStrings} />).toBlob();
    const file = new File([blob], `${customerContract?.contractNo} Proforma Invoice.pdf`)

    const metadata = {
      fileName: file.name,
      contractId: contractId,
      uploadedBy: uploadedBy,
    }

    await uploadToBlobClient(file, metadata, accounts, instance)

    // Log the proforma was created
    await logProformaInvoiceCreated(accounts, instance, +contractId, +deliveryDetailsFormValues.depotId)

    try {
      downloadBlob(blob, file.name)
    } catch (err) {
      console.error(err)
    }
  }

  const initialModalState = { mode: "", isOpen: false }
  const [modalState, setModalState] = useState(initialModalState)

  const closeModal = () => {
    setModalState(prevState => ({ ...prevState, isOpen: false }))
  }

  if (isCustomerLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full p-44">
        <Loader />
        <p className="text-xl animate-pulse">Loading customer...</p>
      </div>
    )
  }
  if (isContractLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full p-44">
        <Loader />
        <p className="text-xl animate-pulse">Loading contract...</p>
      </div>
    )
  }

  const {
    hasInsurance,
    accidentalDamageWaiverPercentage,
    theftAndLossWaiverPercentage,
  } = customer || {}

  const emailConfirmationCheckBox: ICheckBox = {
    label: "Send confirmation email to customer",
    defaultChecked: true,
    onChange: (e: any) => {
      suppressEmailNotification.current = !e.target.checked
    }
  }

  const setContractDocumentsList = () => {
    setContractDocuments([])
    if (contractId) {
      listDocuments(accounts, instance, contractId)
        .then((results: any) => {
          setContractDocuments(results?.data?.internalListDocumentsByContractId?.files || [])
        })
    }
  }

  const getPreviousEquipmentChargesByEquipment = (equipmentCode: string, chargePeriod: string) => {
    let equipmentCharges: any = []
    if (previousChargesByEquipment.hasOwnProperty(equipmentCode)) {
      // Previously got any charges for this equipment, so use that object
      equipmentCharges = previousChargesByEquipment[equipmentCode]
    }

    // Has the charges array already been populated for this charge period?
    const hasChargePeriod =
      equipmentCharges &&
      equipmentCharges.filter((equipmentCharges: any) => { return equipmentCharges.chargePeriod === chargePeriod }).length

    if (!hasChargePeriod) {
      // Nope, get and add any charges for the new charge period and add it to the equipment charges
      getPreviousEquipmentChargesByCustomer(accounts, instance, customerId, equipmentCode, chargePeriod, contractId)
        .then((results: Record<string, any>) => {
          const charges = results?.data?.internalGetPreviousEquipmentChargesByCustomer?.charges || []
          equipmentCharges.push({
            chargePeriod,
            charges,
          })
          setPreviousChargesByEquipment({
            ...previousChargesByEquipment,
            [equipmentCode]: equipmentCharges,
          })
        })
        .catch(error => {
          console.log(error)
        })
    }

    setPreviousChargesByEquipment({
      ...previousChargesByEquipment,
      [equipmentCode]: equipmentCharges,
    })
  }

  return customer ? (
    <div>
      <div className="grid grid-cols-12 gap-8 mt-4 mb-8">
        <div className="col-span-12 gap-8 mr-3 md:col-span-8">
          <h1 className="inline-flex mb-6 mr-6 text-3xl font-bold uppercase">
            {`${customer?.customerName ? customer?.customerName.toUpperCase() : ""}${customer?.accountNo ? ` (${customer?.accountNo})` : ""}`}
          </h1>
          <div className="flex flex-row justify-between">
            <p className="flex flex-col text-3xl font-bold uppercase">
              Contract #{customerContract?.contractNo}
            </p>
            {/* Can only duplicate if survey not pending */}
            <div className="flex flex-col">
              <div className="flex flex-row">
                {!customerContract?.isSiteSurvey && (
                  <Button
                    className={`px-6${isSaving ? " cursor-not-allowed" : ""}`}
                    onClick={handleOnClick("duplicateContract")}
                    disabled={isContractLoading || isSaving || isCancelling}
                  >
                    DUPLICATE CONTRACT
                  </Button>
                )}
                <Button
                  outline
                  className={`ml-3 px-6${isCancelling ? " cursor-not-allowed" : ""}`}
                  onClick={handleOnClick("cancelContract")}
                  disabled={isContractLoading || isSaving || isCancelling}
                >
                  CANCEL CONTRACT
                </Button>
              </div>
            </div>
          </div>

        </div>
        <div className="col-span-12 md:col-span-4">
          <Select
            className="basic-single"
            classNamePrefix="select"
            value={{
              label: contractTypeMap[contractType],
              value: contractType,
            }}
            name="contractStatus"
            options={contractTypeOptions}
            onChange={(option: any) => {
              setContractType(option?.value)
            }}
            isSearchable={false}
          />
          {canFlipToFirmBookingWithAuthCode && (
            <button
              className="underline sm:text-xs"
              onClick={(e) => {
                e.preventDefault()
                setIsAuthorisationCodeModalOpen(true)
              }}
            >
              Flip to Firm Booking with authorisation code
            </button>
          )}
        </div>
      </div>

      <SiteSurveyStatus
        isSiteSurvey={customerContract?.isSiteSurvey}
        siteSurveyAssigned={customerContract?.siteSurveyAssigned}
        handleAssignSurveyor={() => {
          setSiteSurveyAssignSurveyorModalOpen(true)
        }}
        handleCompleteSurvey={() => {
          setSiteSurveyModalOpen(true)
        }}
      />

      <AlertMessage
        mode="danger"
        messages={[alertOnHold]}
        show={statusIsOnHold}
      />

      <AlertMessage
        mode="danger"
        messages={["The customer has exceeded their credit limit"]}
        show={statusHasExceededCreditLimit}
      />

      <AlertMessage
        mode="warning"
        messages={["Prospect customer"]}
        show={isProspect}
      />

      <AlertMessage
        mode="warning"
        messages={["Transport rate has not been set for this customer. Ensure that delivery and collection charges are correct before confirming."]}
        show={!customer.transportRate}
      />

      <AlertMessage
        mode="warning"
        messages={["Delivery or collection charge is not set or is zero"]}
        show={isEmptyTransportCharges}
      />

      <AlertMessage
        mode="warning"
        messages={[`${isDuplicating ? "Duplicating" : "Cancelling"} the contract ...`]}
        animate
        show={isDuplicating || isCancelling}
      />

      <div className="grid grid-cols-12 gap-8 mt-4 mb-8">
        <div className="col-span-12 gap-8 mr-3 md:col-span-8">
          <section>
            <h2 className="mt-4 mb-4 text-xl font-bold uppercase">
              {deliveryDetails}
            </h2>

            <DeliveryDetailsForm
              deliverySites={deliverySites}
              deliveryContacts={deliveryContacts}
              deliveryTimeOptions={deliveryTimeOptions}
              deliveryDetailsFormValues={deliveryDetailsFormValues}
              handleDeliveryDetailChange={handleDeliveryDetailChange}
              excludedPostcode={excludedPostcode}
              previousExcludedPostcodeTransportCharges={previousExcludedPostcodeTransportCharges}
              additionalTransport={additionalTransport}
              isZeroCustomerTransportRateWarningVisible={isZeroCustomerTransportRateWarningVisible}
            />
          </section>
          {/* Can only add items if survey not pending */}
          {!customerContract?.isSiteSurvey && (
            <section>
              <div className="flex flex-row items-center">

                <h2 className="mt-4 mb-4 text-xl font-bold uppercase">
                  {hireDetails}
                </h2>
                <button
                  onClick={e => {
                    e.preventDefault()
                    setIsFleetDayBookModalOpen(true)
                  }}
                  className="ml-4 text-right underline sm:text-xs"
                >
                  Fleet day book
                </button>
              </div>
              <HireDetailsForm
                equipmentListLoading={isEquipmentListLoading}
                hirePeriodOptions={hirePeriodOptions}
                equipmentList={equipmentList}
                hireDetailsFormValuesArr={hireDetailsFormValuesArr}
                handleHireDetailArrChange={handleHireDetailArrChange}
                addOrDeleteHireDetailItem={addOrDeleteHireDetailItem}
                getPreviousEquipmentChargesByEquipment={getPreviousEquipmentChargesByEquipment}
                previousChargesByEquipment={previousChargesByEquipment}
                handleDeliveryDetailChange={handleDeliveryDetailChange}
                disableAddHireDetailItem={disableAddHireDetailItem}
              />
            </section>
          )}
          <section>
            <h2 className="mt-4 mb-4 text-xl font-bold uppercase">
              {orderDetails}
            </h2>
            <div className="bg-white border sm:rounded-lg">
              <div className="p-4">
                <OrderDetailsForm
                  contractId={contractId}
                  contactsList={orderContacts}
                  orderDetailsFormValues={orderDetailsFormValues}
                  handleOrderDetailChange={handleOrderDetailChange}
                  orderDetailEnquiryTypes={orderDetailEnquiryTypes}
                  automaticDeliveryNotes={automaticDeliveryNotes}
                  contractDocuments={contractDocuments}
                  handleRefreshContractDocuments={setContractDocumentsList}
                  purchaseOrderNoValidation={purchaseOrderNoValidation}
                  isQuote={contractType === 'Quotation'}
                />
              </div>
            </div>
          </section>
          <AlertMessage
            mode="warning"
            messages={["Saving your changes ..."]}
            animate
            show={isSaving}
          />
          <AlertMessage
            mode={
              formStatus.status === "error"
                ? "danger"
                : formStatus.status === "success"
                  ? "success"
                  : ""
            }
            messages={formStatus?.messages}
            show={formStatus?.messages?.length > 0}
          />
          <section className="flex flex-wrap justify-between my-6">
            <div className="flex items-center mb-5">
              <Button
                className={`px-6${isSaving ? "cursor-not-allowed" : ""}`}
                color="grey"
                onClick={handleOnClick("cancel")}
                disabled={isSaving}
              >
                CANCEL CHANGES
              </Button>
            </div>
            {!customerContract?.isSiteSurvey && (
              <div className="flex items-center mb-5">
                <Button
                  className={`px-6${isSaving ? "cursor-not-allowed" : ""}`}
                  onClick={handleProformaInvoice}
                  disabled={isSaving}
                >
                  PROFORMA INVOICE
                </Button>
              </div>
            )}
            <div className="flex items-center mb-5">
              <Button
                outline
                className={`mr-3 ${isSaving ? "cursor-not-allowed" : ""
                  }`}
                onClick={handleOnClick("save")}
                disabled={isSaving}
              >
                SAVE
              </Button>
              {/* Can only confirm if survey not pending */}
              {!customerContract?.isSiteSurvey && (
                <Button
                  className={isSaving ? "cursor-not-allowed" : ""}
                  onClick={handleOnClick("confirm")}
                  disabled={isSaving}
                >
                  CONFIRM
                </Button>
              )}
            </div>
          </section>
        </div>

        <div className="col-span-12 md:col-span-4">
          <Summary
            ref={summaryRef}
            summary={summary}
            contractType={contractType}
            customerContract={customerContract}
            hireDetails={hireDetailsFormValuesArr}
            deliveryDetails={deliveryDetailsFormValues}
            orderDetails={orderDetailsFormValues}
            autoNotes={automaticDeliveryNotes}
            hasInsurance={hasInsurance !== "false"}
            accidentalDamageWaiverPercentage={accidentalDamageWaiverPercentage}
            theftAndLossWaiverPercentage={theftAndLossWaiverPercentage}
            customer={customer}
            excludedPostcode={excludedPostcode.excluded}
            deliveryTimeOptions={deliveryTimeOptions}
          />
        </div>
      </div>
      <ConfirmationModal
        isLoading={isSaving}
        customerId={customerId}
        mode={modalState.mode}
        isOpen={modalState.isOpen}
        closeModal={closeModal}
        handleConfirm={handleConfirmationModalConfirm}
        checkBox={emailConfirmationCheckBox}
        cancellationReasons={cancellationReasons}
        onChangeCancellationReason={(reason) => setCancellationReason(reason)}
      />
      <SiteSurveyAssignSurveyorModal
        contract={customerContract}
        handleSiteSurveyAssignSurveyorCompleted={handleSiteSurveyAssignSurveyorCompleted}
        isOpen={isSiteSurveyAssignSurveyorModalOpen}
        setIsOpen={setSiteSurveyAssignSurveyorModalOpen}
      />
      <SiteSurveyCompletionModal
        contract={customerContract}
        handleSiteSurveyCompleted={handleSiteSurveyCompleted}
        isOpen={isSiteSurveyModalOpen}
        setIsOpen={setSiteSurveyModalOpen}
      />
      <FleetDayBookModal
        isFleetDayBookModalOpen={isFleetDayBookModalOpen}
        setIsFleetDayBookModalOpen={setIsFleetDayBookModalOpen}
        initialCategory={
          hireDetailsFormValuesArr?.length === 1 ? hireDetailsFormValuesArr[0].equipment?.value?.pricingEquipmentClass : ""
        }
      />
      <ContractAuthorisationCodeModal
        contractId={customerContract?.contractId}
        isOpen={isAuthorisationCodeModalOpen}
        setIsOpen={setIsAuthorisationCodeModalOpen}
        successCallback={() => window.location.reload()}
      />
      <LoadingFeedback
        showSpinner={isCreatingProforma}
        loadingText="Creating proforma invoice..."
        translucentBackground={true}
      />
      <Modal
        children={
          <>
            <div className="flex flex-col justify-center">
              <p className="mt-4">Ensure that the contract has been saved before continuing.</p>
              {hasExistingProforma && (
                <p className="mt-4 mb-2 font-semibold leading-5">NOTE: There is already a proforma invoice PDF for this contract.</p>
              )}
              <p className="mt-2">Are you sure you want to generate a new proforma invoice?</p>
            </div>
            <div className="flex flex-row justify-center mt-8">
              <Button
                outline
                className="mr-3"
                onClick={() => setIsProformaInvoicePDFModalOpen(false)}
              >
                NO
              </Button>
              <Button
                onClick={async () => await confirmProformaInvoicePDF()}
              >
                YES
              </Button>
            </div>
          </>
        }
        closeModal={() => setIsProformaInvoicePDFModalOpen(false)}
        key="proformaInvoicePDF"
        isOpen={isProformaInvoicePDFModalOpen}
        title="PROFORMA INVOICE"
      />
    </div>
  ) : null
}
