import { Font, StyleSheet } from '@react-pdf/renderer'
import FontArimoRegular from "../../src/fonts/arimo-regular.ttf"
import FontArimoBold from "../../src/fonts/arimo-bold.ttf"
import FontArimoBoldItalic from "../../src/fonts/arimo-bold-italic.ttf"

const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
})

export const resolveCurrency = (value: any) => {
    let parsedValue = parseFloat(value)

    if (!isNaN(parsedValue)) {
        // Credit notes will have a negative value, but are shown as a positive
        if (parsedValue < 0) {
            parsedValue = parsedValue * -1
        }

        return currencyFormatter.format(parsedValue)
    }

    return ""
}

export const registerFonts = () => {
    Font.register({
        family: 'Arimo',
        fonts: [
            {
                src: FontArimoRegular,
            },
            {
                src: FontArimoBold,
                fontWeight: 'bold',
            },
            {
                src: FontArimoBoldItalic,
                fontWeight: 'bold',
                fontStyle: 'italic',
            },
        ],
    })
}

export const invoiceStyles = () => {
    return StyleSheet.create({
        body: {
            width: '450px',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        fullWidth: {
            width: '100%',
        },
        divider: {
            borderTop: '1px',
            borderStyle: 'solid',
            borderColor: '#555555',
            width: '100%',
        },
        dividerThick: {
            borderTop: '2px',
            borderStyle: 'solid',
            borderColor: '#555555',
            width: '100%',
        },
        halfContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
        },
        alignRightContainer: {
            alignItems: 'flex-end',
        },
        alignVerticalCenterContainer: {
            justifyContent: 'center',
        },
        spacer: {
            marginTop: '10px',
        },
        spacerSmall: {
            marginTop: '5px',
        },
        spacerLarge: {
            marginTop: '20px',
        },
        spacerExtraLarge: {
            marginTop: '30px',
        },
        text: {
            fontFamily: 'Arimo',
            fontSize: '8px',
            lineHeight: 1.2,
            color: '#555555',
            marginBottom: '1px',
            padding: 0,
        },
        textSmall: {
            fontSize: '7px',
        },
        textMedium: {
            fontSize: '12px',
        },
        textLarge: {
            fontSize: '16px',
        },
        textBold: {
            fontWeight: 'bold',
        },
        textItalic: {
            fontStyle: 'italic',
        },
        textUpperCase: {
            textTransform: 'uppercase',
        },
        textCenter: {
            textAlign: 'center',
        },
        textRight: {
            textAlign: 'right',
        },
        table: {
            width: '100%',
        },
        tableBorder: {
            width: '100%',
            borderTop: '1px',
            borderLeft: '1px',
            borderStyle: 'solid',
            borderColor: '#555555',
        },
        tableRow: {
            display: 'flex',
            flexDirection: 'row',
            margin: 0,
        },
        tableRowBorder: {
            display: 'flex',
            flexDirection: 'row',
            margin: 0,
            borderLeft: '1px',
            borderStyle: 'solid',
            borderColor: '#555555',
        },
        tableCell: {
            display: 'flex',
            flexDirection: 'column',
            margin: 0,
        },
        tableCellBorder: {
            borderRight: '1px',
            borderBottom: '1px',
            borderStyle: 'solid',
            borderColor: '#555555',
            display: 'flex',
            flexDirection: 'column',
            margin: 0,
        },
        tableCellBorders: {
            borderLeft: '1px',
            borderRight: '1px',
            borderTop: '1px',
            borderStyle: 'solid',
            borderColor: '#555555',
            display: 'flex',
            flexDirection: 'column',
            margin: 0,
        },
        tableCellBorderBottom: {
            borderBottom: '1px',
        },
        tableCellQuarter: {
            width: '25%',
        },
        tableCellHalf: {
            width: '50%',
        },
        tableCellText: {
            fontFamily: 'Arimo',
            fontSize: '8px',
            color: '#555555',
            margin: '0 6px 1px',
        },
        tableCellTextLarge: {
            fontFamily: 'Arimo',
            fontSize: '10px',
            color: '#555555',
            margin: '0 6px 1px',
        },
        tableCellTitle: {
            width: '35%',
        },
        tableCellContent: {
            width: '65%',
        },
        tableCellLineQty: {
            padding: '2px 1px 1px',
            width: '8%',
        },
        tableCellLineDate: {
            padding: '2px 1px 1px',
            width: '12%',
        },
        tableCellLineValue: {
            padding: '2px 1px 1px',
            width: '16%',
        },
        tableCellLineChargePer: {
            padding: '2px 1px 1px',
            width: '12%',
        },
        tableCellLineVAT: {
            padding: '2px 1px 1px',
            width: '18%',
        },
        tableCellLineDescription: {
            padding: '2px 1px 1px',
            width: '42%',
        },
        tableCellLineDescriptionProforma: {
            padding: '2px 1px 1px',
            width: '30%',
        },
        imageHorizonPlatformsLogo: {
            width: '50px',
            height: 'auto',
            marginLeft: '30px',
        },
        paddingX: {
            paddingLeft: '20px',
            paddingRight: '20px',
        },
    })
}