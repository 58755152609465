import React, { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react"
import { useCustomerContext } from "../../store"
import { NewContractButton } from "../../../../../new-contract-button"
import { getInvoice } from "../../../../../../middleware/middleware-layer"
import { LoadingFeedback } from "../../../../../loading-feedback"
import moment from "moment"
import { Button } from "../../../../../button"
import { InvoicePDF } from "../../../../../../pdf-templates/invoice"
import { pdf } from "@react-pdf/renderer"
import { IInvoice } from "../../../../../../types/interfaces/IInvoice"
import { capitalize } from "lodash"
import { downloadBlob } from "../../../../../../utils/download-helper"
import _ from "lodash"
import { useResourceStringsInvoices } from "../../../../../../pdf-templates/use-resource-strings"

export default function InvoiceDetails(props: any) {
    const { instance, accounts } = useMsal()
    const { customer } = useCustomerContext()
    const resourceStrings = useResourceStringsInvoices()

    const [isLoading, setIsLoading] = useState(true)
    const [invoiceDetails, setInvoiceDetails] = useState<Record<string, any>>()

    const currencyFormatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });

    const resolveInvoiceTitle = () => {
        return invoiceDetails?.header.invoiceType === "CREDIT"
            ? "Credit Note"
            : `${capitalize(invoiceDetails?.header.invoiceType)} Invoice`
    }

    const resolveCurrency = (value: any) => {
        let parsedValue = parseFloat(value)

        if (!isNaN(parsedValue)) {
            // Credit notes will have a negative value, but are shown as a positive
            if (parsedValue < 0) {
                parsedValue = parsedValue * -1
            }

            return currencyFormatter.format(parsedValue)
        }

        return ""
    }

    const handleDownloadPDF = async () => {
        const invoice: IInvoice = invoiceDetails as IInvoice
        const title = resolveInvoiceTitle()

        const blob = await pdf(<InvoicePDF invoice={invoice} title={title} customer={customer} resourceStrings={resourceStrings} />).toBlob();
        const fileName = `${title} ${invoiceDetails?.header.invoiceNo}.pdf`

        try {
            downloadBlob(blob, fileName)
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        async function initialLoad() {
            await getInvoice(accounts, instance, props.customerId, props.invoiceNo)
                .then((results: any) => {
                    setInvoiceDetails(results?.data?.internalGetInvoice || {})
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
        initialLoad()
    }, [])

    return (
        <>
            <LoadingFeedback
                loadingText="Loading invoice..."
                showSpinner={isLoading}
                translucentBackground={true}
            />
            {!isLoading && (
                <div>
                    <div className="flex flex-row justify-end">
                        <h2 className="flex-1 inline-flex mt-8 mb-6 mr-6 text-3xl font-bold uppercase">
                            {customer?.customerName && customer?.customerName.toUpperCase()}
                        </h2>
                        <NewContractButton
                            accounts={accounts}
                            className="mt-8"
                            customer={customer}
                            instance={instance}
                        />
                    </div>
                    <div className="flex flex-row w-4/5 mt-4">
                        <div className="flex flex-col flex-grow text-3xl font-bold uppercase">
                            {`${resolveInvoiceTitle()} No: ${invoiceDetails?.header.invoiceNo}`}
                        </div>
                        <div className="flex flex-col">
                            <Button
                                className="px-6"
                                onClick={handleDownloadPDF}
                            >
                                DOWNLOAD PDF
                            </Button>
                        </div>
                    </div>
                    <div className="flex flex-col w-4/5 mt-8 p-8 text-sm bg-white border rounded-md">
                        <div className="flex flex-row w-full">
                            <div className="flex flex-col w-1/2">
                                <div className="flex flex-row w-full">
                                    <div className="flex flex-col w-full">
                                        {
                                            _.uniq([
                                                customer?.customerName,
                                                customer?.addressLine1,
                                                customer?.addressLine2,
                                                customer?.addressLine3,
                                                customer?.addressTown,
                                                customer?.addressCounty,
                                                customer?.addressPostcode,
                                            ])
                                                .filter(Boolean)
                                                .map((item: string) => {
                                                    return (
                                                        <p className="">{item}</p>
                                                    )
                                                })
                                        }
                                        <p className="mt-4">{customer?.accountNo}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-1/2 space-y-2">
                                <div className="flex flex-row w-full">
                                    <div className="flex flex-col w-1/4 font-semibold">
                                        Site:
                                    </div>
                                    <div className="flex flex-col w-3/4">
                                        {
                                            _.uniq([
                                                invoiceDetails?.header.siteName,
                                                invoiceDetails?.header.siteLine1,
                                                invoiceDetails?.header.siteLine2,
                                                invoiceDetails?.header.siteLine3,
                                                invoiceDetails?.header.siteTown,
                                                invoiceDetails?.header.siteCounty,
                                                invoiceDetails?.header.sitePostcode,
                                            ])
                                                .filter(Boolean)
                                                .map((item: string) => {
                                                    return (
                                                        <p className="leading-5">{item}</p>
                                                    )
                                                })
                                        }
                                    </div>
                                </div>
                                <div className="flex flex-row w-full">
                                    <div className="flex flex-col w-1/4 font-semibold">
                                        Invoice Date:
                                    </div>
                                    <div className="flex flex-col w-3/4">
                                        {moment(invoiceDetails?.header.invoiceDate).format("DD/MM/YYYY")}
                                    </div>
                                </div>
                                <div className="flex flex-row w-full">
                                    <div className="flex flex-col w-1/4 font-semibold">
                                        PO No:
                                    </div>
                                    <div className="flex flex-col w-3/4">
                                        {invoiceDetails?.header.customerRef}
                                    </div>
                                </div>
                                <div className="flex flex-row w-full">
                                    <div className="flex flex-col w-1/4 font-semibold">
                                        Contract No:
                                    </div>
                                    <div className="flex flex-col w-3/4">
                                        {invoiceDetails?.header.contractId}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row w-full mt-8">
                            <table className="w-full text-left">
                                <thead>
                                    <tr>
                                        <th className="w-[10%]">From</th>
                                        <th className="w-[10%]">To</th>
                                        <th>Description</th>
                                        <th className="w-[15%] text-right">Value</th>
                                        <th className="w-[20%] text-right">VAT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        invoiceDetails?.items.map((line: any) => (
                                            <tr className="align-top">
                                                <td className="w-[10%]">{line.dateFrom ? moment(line.dateFrom).format("DD/MM/YYYY") : null}</td>
                                                <td className="w-[10%]">{line.dateTo ? moment(line.dateTo).format("DD/MM/YYYY") : null}</td>
                                                <td className="leading-5">{line.invoiceDetails}</td>
                                                <td className="w-[15%] text-right">{resolveCurrency(line.lineAmount)}</td>
                                                <td className="w-[20%] text-right">{`${resolveCurrency(line.vatAmount)} @ ${line.vatRate}%`}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="flex flex-row w-full mt-8 justify-end">
                            <table className="w-1/6 text-left">
                                <tbody>
                                    <tr>
                                        <td className="w-1/2 font-semibold">Sub-Total</td>
                                        <td className="w-1/2 text-right">{resolveCurrency(invoiceDetails?.header.lineTotal)}</td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/2 font-semibold">VAT</td>
                                        <td className="w-1/2 text-right">{resolveCurrency(invoiceDetails?.header.vatTotal)}</td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/2 font-semibold">TOTAL</td>
                                        <td className="w-1/2 text-right">{resolveCurrency(invoiceDetails?.header.invoiceTotal)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {invoiceDetails?.header.invoiceNotes && (
                            <p className="mt-4 text-center">{invoiceDetails.header.invoiceNotes}</p>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}