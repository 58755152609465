import React, { useState } from "react"
import { useMsal } from "@azure/msal-react"
import { Modal } from "../modal"
import { LoadingFeedback } from "../loading-feedback"
import AlertMessage from "../alert-message"
import { flipOnHoldCustomerQuoteToFirmBooking } from "../../middleware/middleware-layer"

export interface IContractAuthorisationCodeModal {
    isOpen: boolean
    setIsOpen: (bool: boolean) => void
    contractId: number
    successCallback: () => void
}

export const ContractAuthorisationCodeModal = (props: IContractAuthorisationCodeModal) => {
    const { accounts, instance } = useMsal()
    const [authorisationCode, setAuthorisationCode] = useState<string>("")
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [submitErrorMessage, setSubmitErrorMessage] = useState<string[]>([])

    const submitButtonEnabled = authorisationCode.match(/^\d{4}$/)

    function closeModal() {
        props.setIsOpen(false)
        setSubmitErrorMessage([])
        setAuthorisationCode("")
    }

    const handleOnSubmit = async () => {
        setSubmitErrorMessage([])
        setIsSaving(true)

        await flipOnHoldCustomerQuoteToFirmBooking(
            accounts,
            instance,
            Number(props.contractId),
            authorisationCode,
        )
            .then((result) => {
                const {
                    success,
                    errorMessage,
                } = result.data?.internalFlipOnHoldCustomerQuoteToFirmBooking

                if (!success) {
                    setSubmitErrorMessage([
                        errorMessage || `There was a problem authorising the contract."}.`
                    ])
                } else {
                    props.successCallback()
                    closeModal()
                }
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setIsSaving(false)
            })
    }

    return (
        <Modal
            isOpen={props.isOpen}
            closeModal={closeModal}
            title="ENTER CODE"
            widthClassName="w-[24rem]"
        >
            <>
                <LoadingFeedback
                    showSpinner={isSaving}
                    fitParent={true}
                    loadingText="Authorising..."
                    translucentBackground={true}
                />
                <div className="flex flex-col w-full px-4 pt-4 text-left gap-y-2">
                    <div className="flex flex-row w-full items-center">
                        <div className="flex flex-col w-2/3">
                            <p>Authorisation code:</p>
                        </div>
                        <div className="flex flex-col w-1/3">
                            <input
                                type="text"
                                placeholder="Code"
                                value={authorisationCode}
                                onChange={(e: any) =>
                                    setAuthorisationCode(e.target.value)
                                }
                                className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>
                    <AlertMessage
                        mode="danger"
                        messages={submitErrorMessage}
                        show={submitErrorMessage.length > 0}
                    />
                    <div className="flex flex-row gap-8 mt-8">
                        <div className="flex flex-col w-1/2">
                            <button
                                onClick={e => {
                                    e.preventDefault()
                                    closeModal()
                                }}
                                className="px-8 py-2 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                            >
                                CANCEL
                            </button>
                        </div>
                        <div className="flex flex-col w-1/2">
                            <button
                                onClick={async (e) => {
                                    e.preventDefault()
                                    await handleOnSubmit()
                                }}
                                disabled={!submitButtonEnabled}
                                className={submitButtonEnabled
                                    ? "w-full px-8 py-2 text-white rounded-full bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm cursor-pointer"
                                    : "w-full px-8 py-2 bg-white border border-gray-200 text-gray-200 rounded-full sm:text-sm"
                                }
                            >
                                AUTHORISE
                            </button>
                        </div>
                    </div>
                </div>
            </>
        </Modal>
    )
}