import React from "react"
import { horizomPlatformsLogo } from '../base64-images'
import { invoiceStyles, resolveCurrency } from "../use-invoice"
import {
    Image,
    Text,
    View,
} from '@react-pdf/renderer'

const styles = invoiceStyles()

export interface IInvoiceFooterProps {
    showBankDetails: boolean
    subTotal: any
    vatTotal: any
    total: any
    resourceStrings: any
}

export const InvoiceFooter = (props: IInvoiceFooterProps) => {
    return (
        <>
            <View style={styles.spacerLarge}></View>
            <View style={styles.spacerLarge}></View>
            <View style={styles.container}>
                <Text style={[styles.text, styles.textBold]}>
                    VAT No.&nbsp;
                    {props.resourceStrings.invoiceVATNumber}
                </Text>
            </View>
            <View style={styles.dividerThick}></View>
            <View style={styles.spacer}></View>
            <View style={styles.container}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCell, styles.tableCellQuarter]}>
                            <Image style={styles.imageHorizonPlatformsLogo} src={horizomPlatformsLogo}></Image>
                        </View>
                        <View style={[styles.tableCell, styles.tableCellHalf]}>
                            {props.showBankDetails && (
                                <>
                                    <Text style={[styles.text, styles.textSmall, styles.textBold, styles.textUpperCase]}>
                                        {props.resourceStrings.invoiceThankYou}
                                    </Text>
                                    <Text style={[styles.text, styles.textSmall]}>
                                        Bank - &nbsp;
                                        {props.resourceStrings.invoiceBankNameAddress}
                                    </Text>
                                    <Text style={[styles.text, styles.textSmall]}>
                                        Account Name - &nbsp;
                                        {props.resourceStrings.invoiceBankAccountName}
                                    </Text>
                                    <Text style={[styles.text, styles.textSmall]}>
                                        Sort Code - &nbsp;
                                        {props.resourceStrings.invoiceBankAccountSortCode}
                                    </Text>
                                    <Text style={[styles.text, styles.textSmall]}>
                                        Account No - &nbsp;
                                        {props.resourceStrings.invoiceBankAccountNumber}
                                    </Text>
                                </>
                            )}
                        </View>
                        <View style={[styles.tableCell, styles.tableCellQuarter]}>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={[styles.tableCell, styles.tableCellHalf]}>
                                        <Text style={styles.tableCellText}>Sub-Total</Text>
                                    </View>
                                    <View style={[styles.tableCell, styles.tableCellHalf, styles.tableCellBorders]}>
                                        <Text style={[styles.tableCellText, styles.textRight]}>{resolveCurrency(props.subTotal)}</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={[styles.tableCell, styles.tableCellHalf]}>
                                        <Text style={styles.tableCellText}>VAT</Text>
                                    </View>
                                    <View style={[styles.tableCell, styles.tableCellHalf, styles.tableCellBorders]}>
                                        <Text style={[styles.tableCellText, styles.textRight]}>{resolveCurrency(props.vatTotal)}</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={[styles.tableCell, styles.tableCellHalf]}>
                                        <Text style={[styles.tableCellText, styles.textUpperCase]}>Total</Text>
                                    </View>
                                    <View style={[styles.tableCell, styles.tableCellHalf, styles.tableCellBorders, styles.tableCellBorderBottom]}>
                                        <Text style={[styles.tableCellText, styles.textRight]}>{resolveCurrency(props.total)}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.spacer}></View>
            <View style={styles.divider}></View>
            <View style={styles.spacer}></View>
            <View style={styles.container}>
                <Text style={[styles.fullWidth, styles.text, styles.textSmall, styles.textCenter]}>
                    {props.resourceStrings.invoiceFooterAddress}
                </Text>
            </View>
        </>
    )
}