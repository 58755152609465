import { useAllKontentResourceStringData } from "../graphql-static/use-resource-strings"

import { AuthenticatedResourcesStrings } from "../types/enums/authenticated-resource-strings"
import { getResourceStringByid } from "../utils/resource-string-helper"

export const useResourceStringsInvoices = () => {
    const resourceStrings = useAllKontentResourceStringData()

    const invoiceVATNumber = getResourceStringByid(
        resourceStrings,
        AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_VAT_NO
    )

    const invoiceThankYou = getResourceStringByid(
        resourceStrings,
        AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_THANK_YOU
    )

    const invoiceBankNameAddress = getResourceStringByid(
        resourceStrings,
        AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_BANK_NAME_ADDRESS
    )

    const invoiceBankAccountName = getResourceStringByid(
        resourceStrings,
        AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_BANK_ACCOUNT_NAME
    )

    const invoiceBankAccountSortCode = getResourceStringByid(
        resourceStrings,
        AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_BANK_ACCOUNT_SORT_CODE
    )
    
    const invoiceBankAccountNumber = getResourceStringByid(
        resourceStrings,
        AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_BANK_ACCOUNT_NUMBER
    )

    const invoiceFooterAddress = getResourceStringByid(
        resourceStrings,
        AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_FOOTER_ADDRESS
    )

    return {
        invoiceVATNumber,
        invoiceThankYou,
        invoiceBankNameAddress,
        invoiceBankAccountName,
        invoiceBankAccountSortCode,
        invoiceBankAccountNumber,
        invoiceFooterAddress,
    }
}