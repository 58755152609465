import React, { Fragment, useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { debounceValue } from "../../utils/debounce"
import { getSiteSurveyors, getSupplierAddressesbySearchTerm, submitSiteSurveyAssignSurveyor } from "../../middleware/middleware-layer"
import { useMsal } from "@azure/msal-react"
import CheckboxRound from "../checkbox-round"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import AlertMessage from "../alert-message"

export interface ISiteSurveyAssignSurveyorModalProps {
    isOpen: boolean
    setIsOpen: (bool: boolean) => void
    contract: Record<string, any> | null
    handleSiteSurveyAssignSurveyorCompleted: (siteSurveyNotes: string) => void
}

export const SiteSurveyAssignSurveyorModal = (props: ISiteSurveyAssignSurveyorModalProps) => {
    const { instance, accounts } = useMsal()
    const [assignButtonEnabled, setAssignButtonEnabled] = useState<boolean>(false)
    const [surveyors, setSurveyors] = useState<Record<string, any>[]>([])

    const initialAssignSiteSurveyResult = {
        hasResult: false,
        status: "success",
        messages: [],
    }

    const [assignSiteSurveyResult, setAssignSiteSurveyResult] = useState<Record<string, any>>(initialAssignSiteSurveyResult)

    const initialFormDetailsState: Record<string, any> = {
        surveyor: { label: "", value: null },
        isInternal: true,
        supplier: null,
        supplierBranch: null,
        supplierContact: null,
        siteSurveyNotes: null,
    }
    const [formValues, setFormValues] = useState<Record<string, any>>(initialFormDetailsState)

    const handleFormDetailChange = (
        key: string,
        value: string | boolean | number | Record<string, any> | null
    ) => {
        let formValuesToSet = {
            ...formValues
        }
        formValuesToSet[key] = value
        if (key === "supplier") {
            formValuesToSet.supplierBranch = null
            formValuesToSet.supplierContact = null
        }
        if (key === "supplierBranch") {
            formValuesToSet.supplierContact = null
        }
        setFormValues(formValuesToSet)

    }

    const getSuppliers = async (inputValue: string) => {
        if (inputValue.length >= 3) {
            const debouncedInputValue = await debounceValue(inputValue, 500)
            let options = []
            try {
                const supplierBranchResults = await getSupplierAddressesbySearchTerm(
                    accounts,
                    instance,
                    debouncedInputValue as string
                ).then((result: any) => {
                    if (typeof result.data.internalGetSupplierAddressesBySearchTerm !== "undefined") {
                        return result.data.internalGetSupplierAddressesBySearchTerm
                    } else {
                        return []
                    }
                })

                // Get a distinct list of suppliers - this is because the data could contain multiple branches for each 
                // supplier but we only want to show the supplier in the drop down list.
                const distinctSuppliers = supplierBranchResults.filter((supplier: any, index: number) => {
                    return index === supplierBranchResults.findIndex((o: any) => supplier.supplierId === o.supplierId)
                })

                options = distinctSuppliers.map((item: Record<string, any>) => {
                    return {
                        label: item.supplierName,
                        value: {
                            supplierId: item.supplierId,
                            supplierName: item.supplierName,
                            supplierAccountNumber: item.supplierAccountNumber,
                            // Only attach the suppliers branches to the supplier, the original list includes all branches 
                            // for all suppliers
                            branches: supplierBranchResults.filter((branch: any) => {
                                return branch.supplierId === item.supplierId
                            })
                        },
                    }
                })
                return options
            } catch (err) {
                console.log(err)
                return []
            }
        }
    }

    function closeModal() {
        setFormValues(initialFormDetailsState)
        setAssignSiteSurveyResult(initialAssignSiteSurveyResult)
        props.setIsOpen(false)
    }

    const onAssign = async () => {
        setAssignButtonEnabled(false)
        const { value: surveyorData } = formValues.surveyor || {}
        const { value: supplierContactData } = formValues.supplierContact || {}
        const { value: supplierData } = formValues.supplier || {}

        const assignSurveyorData = {
            customerId: +props.contract?.customerId,
            contractId: +props.contract?.contractId,
            isInternal: !!formValues.isInternal,
            surveyorId: +surveyorData?.surveyorId,
            surveyorName: supplierContactData
                ? `${supplierContactData.supplierFirstName || ""}${supplierContactData.supplierFirstName ? " " : ""}${supplierContactData.supplierSurname || ""}`
                : null,
            surveyorEmailAddress: formValues.isInternal ? null : supplierContactData?.supplierEmail || null,
            supplierName: supplierData?.supplierName || null,
            siteSurveyNotes: formValues.siteSurveyNotes,
        }

        submitSiteSurveyAssignSurveyor(
            accounts,
            instance,
            assignSurveyorData,
        )
            .then((result: Record<string, any>) => {
                const {
                    success,
                    errorMessage,
                } = result?.data?.internalSiteSurveyAssignSurveyor

                if (!success) {
                    setAssignSiteSurveyResult({
                        hasResult: true,
                        status: "danger",
                        messages: [
                            "Unable to assign the site survey.",
                            errorMessage || result.errors
                        ]
                    })
                } else {
                    props.handleSiteSurveyAssignSurveyorCompleted(formValues.siteSurveyNotes)
                    closeModal()
                }
            })
            .catch(error => {
                console.log(error)
                setAssignSiteSurveyResult({
                    hasResult: true,
                    status: "danger",
                    messages: [
                        "There was a problem.",
                        error
                    ]
                })
            })
    }

    useEffect(() => {
        if (props.contract?.isSiteSurvey && surveyors.length === 0) {
            getSiteSurveyors(
                accounts,
                instance,
            )
                .then((results: any) => {
                    setSurveyors(
                        results?.data?.internalGetSiteSurveyors.surveyors.map((surveyor: any) => {
                            return {
                                label: surveyor.surveyorName,
                                value: surveyor,
                            }
                        })
                    )
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [props.contract])

    useEffect(() => {
        if (props.isOpen) {
            handleFormDetailChange("siteSurveyNotes", props.contract?.siteSurveyNotes)
        }
    }, [props.isOpen])

    useEffect(() => {
        setAssignButtonEnabled(
            formValues.siteSurveyNotes &&
            (
                (formValues.isInternal && formValues.surveyor?.value) ||
                (!formValues.isInternal && formValues.supplierContact?.value.supplierEmail)
            )
        )
    }, [formValues])

    return (
        <>
            <Transition appear show={props.isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10 font-normal"
                    onClose={closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-xl p-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-xl font-bold leading-6 text-gray-900 text-center"
                                    >
                                        ASSIGN SURVEYOR
                                    </Dialog.Title>
                                    <form>
                                        <div className="flex flex-col w-full mt-8">
                                            <div className="flex flex-row w-full">
                                                <div className="flex flex-col min-w-24">
                                                    <label className="text-sm mt-2 mr-2 pr-[24px]">Requirements:</label>
                                                </div>
                                                <div className="flex flex-col flex-grow justify-center">
                                                    <textarea
                                                        placeholder={"Requirements"}
                                                        className="flex flex-grow w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default sm:text-sm"
                                                        rows={6}
                                                        value={formValues.siteSurveyNotes}
                                                        onChange={(e: any) => handleFormDetailChange("siteSurveyNotes", e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-row w-full mt-8">
                                                <div className="flex flex-col justify-center">
                                                    <CheckboxRound
                                                        checked={formValues?.isInternal}
                                                        id="isInternal"
                                                        name="isInternal"
                                                        onChange={() => {
                                                            handleFormDetailChange("isInternal", true)
                                                        }}
                                                        small={true}
                                                        darkColour={true}
                                                    />

                                                </div>
                                                <div className="flex flex-col min-w-24 justify-center">
                                                    <label className="text-sm ml-2">Internal</label>
                                                </div>
                                                <div className="flex flex-col flex-grow">
                                                    <Select
                                                        isDisabled={!formValues.isInternal}
                                                        id="surveyor"
                                                        value={formValues.surveyor}
                                                        options={surveyors}
                                                        placeholder={"Surveyor"}
                                                        className="text-sm"
                                                        onChange={value => {
                                                            handleFormDetailChange("surveyor", value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-row w-full mt-8">
                                                <div className="flex flex-col justify-center">
                                                    <CheckboxRound
                                                        checked={!formValues?.isInternal}
                                                        id="isPartner"
                                                        name="isPartner"
                                                        onChange={() => {
                                                            handleFormDetailChange("isInternal", false)
                                                        }}
                                                        small={true}
                                                        darkColour={true}
                                                    />
                                                </div>
                                                <div className="flex flex-col min-w-24 justify-center">
                                                    <label className="text-sm ml-2">Partner</label>
                                                </div>
                                                <div className="flex flex-col flex-grow">
                                                    <AsyncSelect
                                                        id="partner"
                                                        isDisabled={formValues.isInternal}
                                                        className="border-gray-300 rounded-md shadow-sm sm:text-sm"
                                                        value={formValues?.supplier}
                                                        cacheOptions
                                                        defaultOptions
                                                        placeholder=""
                                                        loadOptions={getSuppliers}
                                                        onChange={(value: Record<string, any>) => {
                                                            handleFormDetailChange("supplier", value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-row w-full mt-2">
                                                <div className="flex flex-col min-w-[24px]"></div>
                                                <div className="flex flex-col min-w-24 justify-center">
                                                    <label className="text-sm ml-2">Branch</label>
                                                </div>
                                                <div className="flex flex-col flex-grow">
                                                    <Select
                                                        id="partnerBranch"
                                                        isDisabled={formValues.isInternal}
                                                        value={formValues?.supplierBranch}
                                                        isClearable
                                                        className="border-gray-300 rounded-md shadow-sm sm:text-sm"
                                                        onChange={branch =>
                                                            handleFormDetailChange("supplierBranch", branch)
                                                        }
                                                        placeholder=""
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 100, fontSize: '0.875rem', lineHeight: '1.25rem' }) }}
                                                        options={
                                                            formValues?.supplier?.value?.branches?.map(
                                                                (item: any) => {
                                                                    return {
                                                                        label: `${item.supplierAdrName || item.supplierTown || ""}`,
                                                                        value: item,
                                                                    }
                                                                }
                                                            ) || []
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-row w-full mt-2">
                                                <div className="flex flex-col min-w-[24px]"></div>
                                                <div className="flex flex-col min-w-24 justify-center">
                                                    <label className="text-sm ml-2">Contact</label>
                                                </div>
                                                <div className="flex flex-col flex-grow">
                                                    <Select
                                                        id="partnerContact"
                                                        isDisabled={formValues.isInternal}
                                                        value={formValues?.supplierContact}
                                                        isClearable
                                                        className="border-gray-300 rounded-md shadow-sm sm:text-sm"
                                                        onChange={contact =>
                                                            handleFormDetailChange("supplierContact", contact)
                                                        }
                                                        placeholder=""
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 100, fontSize: '0.875rem', lineHeight: '1.25rem' }) }}
                                                        options={
                                                            formValues?.supplierBranch?.value?.contacts?.filter((item: any) => {
                                                                return item.supplierEmail
                                                            }).map((item: any) => {
                                                                return {
                                                                    label: `${item.supplierFirstName || ""}${item.supplierFirstName ? " " : ""}${item.supplierSurname || ""} - 
                                                                        ${item.supplierEmail}`,
                                                                    value: item,
                                                                }
                                                            }) || []
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {assignSiteSurveyResult.hasResult && (
                                            <div className="mt-8 text-center">
                                                <AlertMessage
                                                    mode={assignSiteSurveyResult.status}
                                                    messages={assignSiteSurveyResult.messages}
                                                    show={true}
                                                />
                                            </div>
                                        )}
                                        <div className="flex flex-row justify-around mx-4 mt-8">
                                            <div className="flex items-center mb-5">
                                                <button
                                                    className="px-12 py-2 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                                                    color="grey"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        closeModal()
                                                    }}
                                                >
                                                    {assignSiteSurveyResult.hasResult ? "CLOSE" : "CANCEL"}
                                                </button>
                                            </div>
                                            {!assignSiteSurveyResult.hasResult && (
                                                <div className="flex items-center mb-5">
                                                    <button
                                                        disabled={!assignButtonEnabled}
                                                        className={assignButtonEnabled
                                                            ? "px-12 py-2 text-white rounded-full bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm cursor-pointer"
                                                            : "px-12 py-2 bg-white border border-gray-200 text-gray-200 rounded-full sm:text-sm"
                                                        }
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            onAssign()
                                                        }}
                                                    >
                                                        ASSIGN
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}